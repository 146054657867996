import {createStore} from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'

axios.defaults.baseURL = 'https://api-mars.divtec.ch/api'

export default createStore({
    state: {
        userEmail: '',
        userData: [],
        placedElementList: [],
        baseIndexValue: 500,
        currentSelectedIndex: 500,
        passwordUser: '',
        isModified: false,
        indexIsModified: false,
        userLogedOrGuest: false,
    },
    mutations: {
        setIndex: (state, payload) => {
            state.currentSelectedIndex = payload;
        },

        setUserData(state, datas) {
            state.userData = datas;
        },
        setUserConnection(state, data) {
            state.userLogedOrGuest = data;
        },
        needConnect(state) {
            state.userLogedOrGuest = false;
        }
    },
    actions: {
        setIndexSelected: ({commit}, newValue) => {
            commit('setIndex', newValue)
        },

        // Connecte un joueur
        login({commit}, credentials) {

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true
            })

            // Émets une requête axios en passant l'email et le mot de passe
            return axios
                .post("/login", {
                    email: credentials.email,
                    password: credentials.password
                })
                // une fois la requete
                .then(response => {
                    Toast.fire({
                        icon: "success",
                        title: `Bienvenue ${response.data.user.username} !`
                    })

                    commit('setUserConnection', true)
                    commit('setUserData', response.data)
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        Toast.fire({
                            icon: "error",
                            title: `Adresse mail ou mot de passe invalide !`
                        })
                    }
                })
        },

        // Ajout du website dans la base de données
        addWebsite({commit}, data) {
            let config = {
                "headers": {
                    "Authorization": "Bearer " + data.token,
                }
            }

            console.log(commit);
            return axios
                .put('/users/' + data.id + '/website', {website: data.website}, config)
        }
    },
    modules: {}
})
