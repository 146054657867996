<template>
  <LoginView v-if="this.$store.state.userLogedOrGuest === false"/>
  <MainView v-else/>
</template>

<script>
import MainView from "@/views/HomeView";
import LoginView from "@/views/LoginView";

export default {
  name: "App",
  components: {
    LoginView,
    MainView
  }
}
</script>

<style>

/*******************************************
Imports
*******************************************/
@font-face {
  font-family: "Chakra Petch";
  src: url("@/assets/fonts/Chakra_Petch/ChakraPetch-Regular.ttf") format("truetype");
}
</style>