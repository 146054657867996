<template>
  <div class="div-images">
    <HeaderStyleSection headerName="Choix de l'image" isOnTop/>
    <!-- Bibliothèque d'images -->
    <div class="div-images-library">
      <div class="carousel">
        <input id="image-1" v-model="imageChoice" checked name="slides" type="radio" value="1">
        <input id="image-2" v-model="imageChoice" name="slides" type="radio" value="2">
        <input id="image-3" v-model="imageChoice" name="slides" type="radio" value="3">
        <input id="image-4" v-model="imageChoice" name="slides" type="radio" value="4">
        <input id="image-5" v-model="imageChoice" name="slides" type="radio" value="5">
        <input id="image-6" v-model="imageChoice" name="slides" type="radio" value="6">
        <ul class="carousel__slides">
          <li class="carousel__slide">
            <figure>
              <div>
                <img alt="Planète Mars" src="/images/1.png">
              </div>
            </figure>
          </li>
          <li class="carousel__slide">
            <figure>
              <div>
                <img alt="Rover" src="/images/2.png">
              </div>
            </figure>
          </li>
          <li class="carousel__slide">
            <figure>
              <div>
                <img alt="Paysage Mars" src="/images/3.png">
              </div>
            </figure>
          </li>
          <li class="carousel__slide">
            <figure>
              <div>
                <img alt="Fusée et astronautes" src="/images/4.png">
              </div>
            </figure>
          </li>
          <li class="carousel__slide">
            <figure>
              <div>
                <img alt="Décollage fusée" src="/images/5.png">
              </div>
            </figure>
          </li>
          <li class="carousel__slide">
            <figure>
              <div>
                <img alt="Photo fusée et Mars" src="/images/6.png">
              </div>
            </figure>
          </li>
        </ul>
        <ul class="carousel__thumbnails">
          <li>
            <label for="image-1"><img alt="Planète Mars" class="image-choice" src="/images/1.png"></label>
          </li>
          <li>
            <label for="image-2"><img alt="Rover" class="image-choice" src="/images/2.png"></label>
          </li>
          <li>
            <label for="image-3"><img alt="Paysage Mars" class="image-choice" src="/images/3.png"></label>
          </li>
          <li>
            <label for="image-4"><img alt="Fusée et astronautes" class="image-choice"
                                      src="/images/4.png"></label>
          </li>
          <li>
            <label for="image-5"><img alt="Décollage fusée" class="image-choice" src="/images/5.png"></label>
          </li>
          <li>
            <label for="image-6"><img alt="Photo fusée et Mars" class="image-choice"
                                      src="/images/6.png"></label>
          </li>
        </ul>
      </div>
    </div>
    <HeaderStyleSection headerName="Dimension"/>
    <!-- Largeur de l'image -->
    <div class="div-image-width">
      <b><label for="image-width">Largeur</label></b>
      <div class="div-flex-slider">
        <input id="image-width" v-model="imageWidth" max="320" min="0" name="image-width" type="range">
        <input v-model="imageWidth" type="text">
      </div>
    </div>
    <!-- Hauteur de l'image -->
    <div class="div-image-size">
      <div class="div-image-height">
        <!-- Verrouiller les proportions de l'image -->
        <div class="div-lock-proportions">
          <b><label for="image-height">Hauteur</label></b>
          <b><label class="label-lock-proportions" for="lock-proportions">Verrouiller les proportions</label></b>
          <input id="inpLock" v-model="lockProportions" checked name="lock-proportions" type="checkbox"/>
          <label class="btn-lock" for="inpLock">
            <svg height="24" viewBox="0 0 36 40" width="20">
              <path class="lockb"
                    d="M27 27C27 34.1797 21.1797 40 14 40C6.8203 40 1 34.1797 1 27C1 19.8203 6.8203 14 14 14C21.1797 14 27 19.8203 27 27ZM15.6298 26.5191C16.4544 25.9845 17 25.056 17 24C17 22.3431 15.6569 21 14 21C12.3431 21 11 22.3431 11 24C11 25.056 11.5456 25.9845 12.3702 26.5191L11 32H17L15.6298 26.5191Z"></path>
              <path class="lock" d="M6 21V10C6 5.58172 9.58172 2 14 2V2C18.4183 2 22 5.58172 22 10V21"></path>
              <path class="bling" d="M29 20L31 22"></path>
              <path class="bling" d="M31.5 15H34.5"></path>
              <path class="bling" d="M29 10L31 8"></path>
            </svg>
          </label>
        </div>
        <!-- Hauteur de l'image -->
        <div class="div-flex-slider">
          <input id="image-height" v-model="imageHeight" disabled max="320" min="0" name="image-height" type="range">
          <input id="input-image-height" v-model="imageHeight" disabled type="text">
        </div>
      </div>
    </div>
    <!-- Alignement de l'image -->
    <div class="div-image-alignment">
      <div class="toolbar">
        <div class="icon icon--left" @click="alignementButtonComportment(1)">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar bar--short"></div>
        </div>
        <div class="space"></div>
        <div class="icon icon--center" @click="alignementButtonComportment(2)">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar bar--short"></div>
        </div>
        <div class="space"></div>
        <div class="icon icon--right" @click="alignementButtonComportment(3)">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar bar--short"></div>
        </div>
      </div>
    </div>
    <HeaderStyleSection headerName="Filtres"/>
    <!-- Filtres de l'image -->
    <div class="div-image-filter">
      <!-- Flou de l'image -->
      <div class="div-image-blur">
        <b><label for="image-blur">Flou</label></b>
        <div class="div-flex-slider">
          <input id="image-blur" v-model="imageBlur" max="5" min="0" name="image-blur" type="range">
          <input v-model="imageBlur" type="text">
        </div>
      </div>
      <!-- Contraste de l'image -->
      <div class="div-image-contrast">
        <b><label for="image-contrast">Contraste</label></b>
        <div class="div-flex-slider">
          <input id="image-contrast" v-model="imageContrast" max="200" min="0" name="image-contrast" type="range">
          <input v-model="imageContrast" type="text">
        </div>
      </div>
      <!-- Luminosité de l'image -->
      <div class="div-image-brightness">
        <b><label for="div-image-brightness">Luminosité</label></b>
        <div class="div-flex-slider">
          <input id="image-brightness" v-model="imageBrightness" max="200" min="0" name="image-brightness" type="range">
          <input v-model="imageBrightness" type="text">
        </div>
      </div>
      <!-- niveaux de gris de l'image -->
      <div class="div-image-grayscale">
        <b><label for="div-image-grayscale">Niveaux de gris</label></b>
        <div class="div-flex-slider">
          <input id="image-grayscale" v-model="imageGrayscale" max="100" min="0" name="image-grayscale" type="range">
          <input v-model="imageGrayscale" type="text">
        </div>
      </div>
    </div>
    <HeaderStyleSection headerName="Bordure"/>
    <!-- Bordure de l'image -->
    <div class="div-image-border">
      <!-- Épaisseur de la bordure de l'image -->
      <div class="div-image-border-width">
        <b><label for="image-border-width">Épaisseur</label></b>
        <div class="div-flex-slider">
          <input id="image-border-width" v-model="imageBorderWidth" max="5" min="0" name="image-border-width"
                 type="range">
          <input v-model="imageBorderWidth" type="text">
        </div>
      </div>
      <!-- Arrondi de l'image -->
      <div class="div-image-radius">
        <b><label for="image-radius">Arrondi</label></b>
        <div class="div-flex-slider">
          <input id="image-radius" v-model="imageRadius" max="50" min="0" name="image-radius" type="range">
          <input v-model="imageRadius" type="text">
        </div>
      </div>
      <!-- Couleur de la bordure de l'image -->
      <div class="div-image-border-color">
        <span class="span-align-border-color-title">
        <span class="span-border-color-title">
        <b><label for="image-border-color">Couleur de la bordure</label></b>
          </span>
          </span>
        <!-- Sélecteur de couleurs -->
        <div class="color-picker">
          <ColorPicker v-model="imageBorderColor" :color="imageBorderColor" default-format="rgb"
                       @color-change="updateColor">
            <template #hue-range-input-label>
              <span class="sr-only"></span>
            </template>
            <template #alpha-range-input-label>
              <span class="sr-only"></span>
            </template>
            <template #copy-button>
              <span class="sr-only">Copy color</span>
              <svg
                  aria-hidden="true"
                  height="15"
                  viewBox="0 0 15 15"
                  width="15"
                  xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5 0v2H1v13h12v-3h-1v2H2V5h10v3h1V2H9V0zm1 1h2v2h3v1H3V3h3z"
                    fill="currentColor"/>
                <path
                    d="M10 7v2h5v2h-5v2l-3-3zM3 6h5v1H3zm0 2h3v1H3zm0 2h3v1H3zm0 2h5v1H3z"
                    fill="currentColor"/>
              </svg>
            </template>
            <template #format-switch-button>
              <span class="sr-only">Switch format</span>
              <svg
                  aria-hidden="true"
                  height="15"
                  viewBox="0 0 16 15"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 15l5-5-1-1-4 2-4-2-1 1zm4-9l1-1-5-5-5 5 1 1 4-2z"
                    fill="currentColor"/>
              </svg>
            </template>
          </ColorPicker>
        </div>
      </div>
      <div class="align-color-presets">
        <div class="color-presets">
          <ColorPreset @changeColor="changeColor"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {ColorPicker} from 'vue-accessible-color-picker'
import HeaderStyleSection from "@/components/HeaderStyleSection";
import ColorPreset from "@/components/ColorPreset";
import Swal from "sweetalert2";

export default {
  name: "StyleImage",
  data: function () {
    return {
      protectorOfRatio: true,
      imageHeight: 182,
      imageWidth: 309,
      ratio: 1.7,
      imageRadius: this.getImageRadius(),
      imageAlignment: this.getImageAlignment(),
      imageFilter: '',
      imageBlur: 0,
      imageContrast: 100,
      imageBrightness: 100,
      imageGrayscale: 0,
      imageBorder: this.getImageBorder(),
      lockProportions: true,
      imageChoice: this.getImageSelected(),
      imageBorderColor: this.getBorderColor(),
      imageBorderWidth: this.getBorderWidth(),
      imageBorderType: "solid",
    }
  },
  components: {
    HeaderStyleSection,
    ColorPicker,
    ColorPreset,
  },
  computed: {
    ...mapState({
      currentSelectedIndex: 'currentSelectedIndex',
    }),
  },
  methods: {
    /**
     * Met à jour la couleur de la bordure de l'image
     * @param payload la couleur sélectionnée
     */
    changeColor(payload) {
      this.imageBorderColor = payload.color;
    },
    /**
     * Modifie le comportement du bouton de l'alignement
     * @param button le bouton
     */
    alignementButtonComportment(button) {
      document.querySelectorAll('.icon').forEach((elem, i) => {
        elem.onclick = () => {
          const toolbarLeft = document.querySelector('.toolbar').getBoundingClientRect().left;
          const elemLeft = elem.getBoundingClientRect().left;
          document.documentElement.style.setProperty('--icon', `${elemLeft - toolbarLeft}px`);
          document.documentElement.style.setProperty('--bar', `${(i - 1) * 25}%`);
        };
      });

      let divLeft = document.querySelector('.icon--left');
      let divCenter = document.querySelector('.icon--center');
      let divRight = document.querySelector('.icon--right');
      if (divLeft !== null && divCenter !== null && divRight !== null) {
        divLeft.style.opacity = 0.2;
        divCenter.style.opacity = 0.2;
        divRight.style.opacity = 0.2;
      }

      switch (button) {
        case 1:
          this.imageAlignment = 'gauche';
          if (divLeft !== null) {
            divLeft.style.opacity = 0.6;
          }
          break;
        case 2:
          this.imageAlignment = 'centre';
          if (divCenter !== null) {
            divCenter.style.opacity = 0.6;
          }
          break;
        case 3:
          this.imageAlignment = 'droite';
          if (divRight !== null) {
            divRight.style.opacity = 0.6;
          }
          break;
      }
    },
    /**
     * Affiche un toast
     * @param icon l'icône
     * @param title le titre
     */
    displayToast(icon, title) {
      // Toast pour informer l'utilisateur
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      })
      Toast.fire({
        icon: icon,
        title: title
      })
    },
    /**
     * Récupère la hauteur de l'image
     * @returns {number} la hauteur de l'image
     */
    getImageHeight() {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      let style = window.getComputedStyle(image, null).getPropertyValue('height');
      return parseInt(style);
    },
    /**
     * Récupère la largeur de l'image
     * @returns {number} la largeur de l'image
     */
    getImageWidth() {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      let style = window.getComputedStyle(image, null).getPropertyValue('width');
      return parseInt(style);
    },
    /**
     * Récupère l'arrondi de l'image
     * @returns {number} l'arrondi de l'image
     */
    getImageRadius() {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      let style = window.getComputedStyle(image, null).getPropertyValue('border-radius');
      return parseInt(style);
    },
    /**
     * Récupère l'alignement de l'image
     * @returns {string} alignement de l'image
     */
    getImageAlignment: function () {
      setTimeout(() => {
        let div = document.getElementById(this.$store.state.currentSelectedIndex);
        let image = div.querySelector("img");

        // Test la valeur des marges pour retourner ou l'élément se situe
        if (parseInt(window.getComputedStyle(image, null).getPropertyValue('margin-left')) === 0) {
          this.alignementButtonComportment(1)
          return "gauche";
        } else if (parseInt(window.getComputedStyle(image, null).getPropertyValue('margin-right')) === 0) {
          this.alignementButtonComportment(3)
          return "droite";
        } else {
          this.alignementButtonComportment(2)
          return "centre";
        }
      }, 100);
    },
    /**
     * Récupère si l'image est bordurée
     * @returns {string} si l'image est bordurée
     */
    getImageBorder: function () {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      let style = window.getComputedStyle(image, null).getPropertyValue('border-style');
      return style;
    },
    /**
     * Récupère l'épaisseur de la bordure
     * @returns {number|number} l'épaisseur de la bordure
     */
    getBorderWidth: function () {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      let style = window.getComputedStyle(image, null).getPropertyValue('border-width');
      return parseInt(style);
    },
    /**
     * Récupère la couleur du texte
     * @returns {string} couleur du texte
     */
    getBorderColor: function () {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      // Retourne la valeur RGB
      let style = window.getComputedStyle(image, null).getPropertyValue('border-color');

      // Parse du string pour retourner seulement le code HEX
      style = style.replaceAll(' ', '');

      let styleR = style.split('(').pop().split(',')[0]
      style = style.replace('rgb(', '');
      style = style.replace(',', ';');
      style = style.replace(styleR, '');

      let styleG = style.split(';').pop().split(',')[0]
      style = style.replace(';', '');
      style = style.replace(styleG, '');

      let styleB = style.split(',').pop().split(')')[0]

      return '#' + this.fullColorHex(styleR, styleG, styleB);
    },
    /**
     * Met à jour la couleur du texte
     * @param eventData
     */
    updateColor(eventData) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let img = div.querySelector("img");
      this.color = eventData.cssColor

      img.style.border = this.imageBorderWidth + "px " + this.imageBorderType + " " + this.color;

      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Converti une couleur en hexadecimal
     * @param color couleur en RGB
     * @returns {string} couleur en hexadecimal
     */
    rgbToHex: function (color) {
      let hex = Number(color).toString(16);
      if (hex.length < 2) {
        hex = "0" + hex;
      }
      return hex;
    },
    /**
     * Converti une couleur en hexadecimal
     * @param r rouge
     * @param g vert
     * @param b bleu
     * @returns {string} couleur en hexadecimal
     */
    fullColorHex: function (r, g, b) {
      let red = this.rgbToHex(r);
      let green = this.rgbToHex(g);
      let blue = this.rgbToHex(b);
      return red + green + blue;
    },
    /**
     * Obtient le filtre sur l'image
     * @returns {string}
     */
    getImageFilter: function () {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      let style = window.getComputedStyle(image, null).getPropertyValue('filter');
      return style;
    },
    getImageSelected() {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      let src = image.src;
      // Tout remplacer jusqu'au dernier /
      src = src.replace(src.substring(0, src.lastIndexOf('/') + 1), "");
      src = src.replace('.png', '');

      if (src === 'planet-mars') {
        src = '1';
      }
      return src;
    }
  },
  watch: {
    /**
     * Change l'index de l'image sélectionnée
     * @param newValue nouvelle valeur de l'index
     */
    currentSelectedIndex: function (newValue) {
      if (newValue !== this.$store.state.baseIndexValue) {
        // Test si c'est une image qui est actuellement sélectionnée
        if (document.getElementById(newValue).classList[0] === "div-img") {
          this.imageHeight = this.getImageHeight();
          this.imageWidth = this.getImageWidth();
          this.imageRadius = this.getImageRadius();
          this.imageAlignment = this.getImageAlignment();
          this.imageBorder = this.getImageBorder();
          this.imageBorderColor = this.getBorderColor();
          this.imageBorderWidth = this.getBorderWidth();
          this.imageChoice = this.getImageSelected();

          // Filtre blur
          let blurString = this.getImageFilter().replace('blur(', '').replace('px)', '').replaceAll(' ', '');
          blurString = blurString.replace(/br.*?\)/, '');
          blurString = blurString.replace(/con.*?\)/, '');
          blurString = blurString.replace(/gr.*?\)/, '');

          blurString = blurString.replace(/bl.*?\(/, '').replace(/\)/, '');

          if (blurString === "none" || blurString === "") {
            this.imageBlur = 0;
          } else {
            this.imageBlur = blurString;
          }

          // Filtre brightness
          let brightnessString = this.getImageFilter();
          brightnessString = brightnessString.replace(/bl.*?\)/, '');
          brightnessString = brightnessString.replace(/con.*?\)/, '');
          brightnessString = brightnessString.replace(/gr.*?\)/, '');

          brightnessString = brightnessString.replace(/br.*?\(/, '').replace(/\)/, '');

          if (brightnessString === "none" || brightnessString === "") {
            this.imageBrightness = 100;
          } else {
            this.imageBrightness = brightnessString * 100;
          }

          // Filtre contrast
          let contrastString = this.getImageFilter();
          contrastString = contrastString.replace(/bl.*?\)/, '');
          contrastString = contrastString.replace(/br.*?\)/, '');
          contrastString = contrastString.replace(/gr.*?\)/, '');

          contrastString = contrastString.replace(/con.*?\(/, '').replace(/\)/, '');

          if (contrastString === "none" || contrastString === "") {
            this.imageContrast = 100;
          } else {
            this.imageContrast = contrastString * 100;
          }

          // Filtre niveau de gris
          let grayscaleString = this.getImageFilter();
          grayscaleString = grayscaleString.replace(/bl.*?\)/, '');
          grayscaleString = grayscaleString.replace(/br.*?\)/, '');
          grayscaleString = grayscaleString.replace(/con.*?\)/, '');

          grayscaleString = grayscaleString.replace(/gr.*?\(/, '').replace(/\)/, '');

          if (grayscaleString === "none" || grayscaleString === "") {
            this.imageGrayscale = 0;
          } else {
            this.imageGrayscale = grayscaleString * 100;
          }

          // Modifie le bit d'archivage
          this.$store.state.isModified = true;
        }
      }
    },
    /**
     * Change la valeur de la hauteur de l'image
     * @param value nouvelle valeur de la hauteur
     * @param oldValue ancienne valeur de la hauteur
     */
    imageHeight: function (value, oldValue) {
      // Retirer les lettres de la valeur
      value = value.replace(/\D/g,'');
      this.imageHeight = value;
      // Teste la valeur voulue par l'utilisateur
      if (value > 320) {
        this.displayToast("warning", "La hauteur ne doit pas dépasser 320px");
        this.imageHeight = oldValue;
      } else if (value < 0) {
        this.displayToast("La hauteur ne doit pas être négative");
        this.imageHeight = this.getImageHeight();
      } else {
        // Applique les changements
        let div = document.getElementById(this.$store.state.currentSelectedIndex);
        let image = div.querySelector("img");
        image.style.height = value + "px";

        // Modifie le bit d'archivage
        this.$store.state.isModified = true;

      }
    },
    /**
     * Change la valeur de la largeur de l'image
     * @param value nouvelle valeur de la largeur
     * @param oldValue ancienne valeur de la largeur
     */
    imageWidth: function (value, oldValue) {
      // Retirer les lettres de la valeur
      value = value.replace(/\D/g,'');
      this.imageWidth = value;
      // Test la valeur voulue par l'utilisateur
      if (value > 320) {
        this.displayToast("warning", "La largeur ne doit pas dépasser 320px");
        this.imageWidth = oldValue;
      } else if (value < 0) {
        this.displayToast("warning", "La largeur ne doit pas être négative");
        this.imageWidth = this.getImageWidth();
      } else {
        // Applique les changements
        let div = document.getElementById(this.$store.state.currentSelectedIndex);
        let image = div.querySelector("img");
        if (this.lockProportions) {
          image.style.width = value + "px";
          image.style.height = value / this.ratio + "px";
          this.imageHeight = Math.round(value / this.ratio);
        } else {
          image.style.width = value + "px";
        }

        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
    /**
     * Verrouille ou non les proportions de l'image
     * @param value nouvelle valeur de la propriété
     */
    lockProportions: function (value) {
      let imageHeightSlider = document.getElementById("image-height");
      let imageHeightInput = document.getElementById("input-image-height");
      if (value === true) {
        imageHeightSlider.disabled = true;
        imageHeightInput.disabled = true;
      } else {
        imageHeightSlider.disabled = false;
        imageHeightInput.disabled = false;
      }
    },
    /**
     * Change la valeur de l'arrondi de l'image
     * @param value nouvelle valeur de l'arrondi
     * @param oldValue ancienne valeur de l'arrondi
     */
    imageRadius: function (value, oldValue) {
      // Retirer les lettres de la valeur
      value = value.replace(/\D/g,'');
      this.imageRadius = value;
      // Teste la valeur voulue par l'utilisateur
      if (value > 50) {
        this.displayToast("warning", "L'arrondi ne doit pas dépasser 50%");
        this.imageRadius = oldValue;
      } else if (value < 0) {
        this.displayToast("warning", "L'arrondi ne doit pas être négatif");
        this.imageRadius = 0;
      } else {
        // Applique les changements
        let div = document.getElementById(this.$store.state.currentSelectedIndex);
        let image = div.querySelector("img");
        image.style.borderRadius = value + "%";

        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },

    /**
     * Change la valeur de l'alignement de l'image
     * @param value nouvelle valeur de l'alignement
     */
    imageAlignment: function (value) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let img = div.querySelector("img");
      switch (value) {
        case "gauche":
          div.style.textAlign = "left";
          img.style.marginRight = "auto";
          img.style.marginLeft = "0";
          break;
        case "centre":
          div.style.textAlign = "center";
          img.style.marginRight = "auto";
          img.style.marginLeft = "auto";
          break;
        case "droite":
          div.style.textAlign = "right";
          img.style.marginRight = "0";
          img.style.marginLeft = "auto";
          break;
      }
      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },

    /**
     * Applique les filtres sur l'image
     */
    imageFilter: function () {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let image = div.querySelector("img");
      image.style.filter = this.imageFilter;
      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Ajoute un flou sur l'image
     * @param value nouvelle valeur du flou
     * @param oldValue ancienne valeur du flou
     */
    imageBlur: function (value, oldValue) {
      // Retirer les lettres de la valeur
      value = value.replace(/\D/g,'');
      this.imageBlur = value;
      // Teste la valeur voulue par l'utilisateur
     if (value > 5) {
        this.displayToast("warning", "Le flou ne doit pas dépasser 5px");
        this.imageBlur = 5;
      } else if (value < 0) {
        console.log("ZERO")
        this.displayToast("warning", "Le flou ne doit pas être négatif");
        this.imageBlur = 0;
        return;
      } else {
        // Applique les changements
        if (this.imageFilter.includes("blur")) {
          this.imageFilter = this.imageFilter.replace("blur(" + oldValue + "px)", "blur(" + value + "px)");
        } else {
          this.imageFilter += "blur(" + value + "px)";
        }
        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
    /**
     * Ajoute un effet de contraste sur l'image
     * @param value nouvelle valeur de l'effet de contraste
     * @param oldValue ancienne valeur de l'effet de contraste
     */
    imageContrast: function (value, oldValue) {
      // Retirer les lettres de la valeur
      value = value.replace(/\D/g,'');
      this.imageContrast = value;
      // test la valeur voulue par l'utilisateur
      if (value > 200) {
        this.displayToast("warning", "Le contraste ne doit pas dépasser 200%");
        this.imageContrast = 200;
      } else if (value < 0) {
        this.displayToast("warning", "Le contraste ne doit pas être négatif");
        this.imageContrast = 0;
      } else {
        // Applique les changements
        if (this.imageFilter.includes("contrast")) {
          this.imageFilter = this.imageFilter.replace("contrast(" + oldValue + "%)", "contrast(" + value + "%)");
        } else {
          this.imageFilter += "contrast(" + value + "%) ";
        }
        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
    /**
     * Ajoute un effet de luminosité sur l'image
     * @param value nouvelle valeur de l'effet de luminosité
     * @param oldValue ancienne valeur de l'effet de luminosité
     */
    imageBrightness: function (value, oldValue) {
      // Retirer les lettres de la valeur
      value = value.replace(/\D/g,'');
      this.imageBrightness = value;
      // Teste la valeur voulue par l'utilisateur
      if (value > 200) {
        this.displayToast("warning", "La luminosité ne doit pas dépasser 200%");
        this.imageBrightness = 200;
      } else if (value < 0) {
        this.displayToast("warning", "La luminosité ne doit pas être négative");
        this.imageBrightness = 0;
      } else {
        // Applique les changements
        if (this.imageFilter.includes("brightness")) {
          this.imageFilter = this.imageFilter.replace("brightness(" + oldValue + "%)", "brightness(" + value + "%)");
        } else {
          this.imageFilter += "brightness(" + value + "%) ";
        }
        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
    /**
     * Ajoute un niveau de gris sur l'image
     * @param value nouvelle valeur du gris
     * @param oldValue ancienne valeur du gris
     */
    imageGrayscale: function (value, oldValue) {
      // Retirer les lettres de la valeur
      value = value.replace(/\D/g,'');
      this.imageGrayscale = value;
      // Teste la valeur voulue par l'utilisateur
      if (value > 100) {
        this.displayToast("warning", "Le niveaux de gris ne peux pas dépasser 100%");
        this.imageGrayscale = 100;
      } else if (value < 0) {
        this.displayToast("warning", "Le niveaux de gris ne doit pas être négatif");
        this.imageGrayscale = 0;
      } else {
        // applique les changements
        if (this.imageFilter.includes("grayscale")) {
          this.imageFilter = this.imageFilter.replace("grayscale(" + oldValue + "%)", "grayscale(" + value + "%)");
        } else {
          this.imageFilter += "grayscale(" + value + "%)";
        }
        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
    /**
     * Choix des images
     * @param value nouvelle valeur de l'image
     */
    imageChoice: function (value) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let img = div.querySelector("img");
      img.src = '/images/' + value + '.png';

      // Switch qui modifie la alt des images
      switch (value) {
        case '1':
          img.alt = 'Planète Mars';
          break;
        case '2':
          img.alt = 'Rover';
          break;
        case '3':
          img.alt = 'Paysage Mars';
          break;
        case '4':
          img.alt = 'Fusée et astronautes';
          break;
        case '5':
          img.alt = 'Décollage fusée';
          break;
        case '6':
          img.alt = 'Photo ville de Mars';
          img.height = img.height / 1;
          break;
      }
      if (this.lockProportions) {
        if (value == "6") {
          this.$data.ratio = 1;
          this.imageHeight = this.getImageWidth();
          this.imageWidth = this.getImageWidth();
          this.protectorOfRatio = false;
        } else {
          if (!this.protectorOfRatio) {
            this.$data.ratio = 1.7;
            this.imageHeight = Math.round(this.getImageHeight() / this.ratio);
            this.imageWidth = this.getImageWidth();
            this.protectorOfRatio = true;
          }
        }

        // Met à jour la taille de l'image
        let div = document.getElementById(this.$store.state.currentSelectedIndex);
        let img = div.querySelector("img");
        img.style.height = this.imageHeight + "px";
        img.style.width = this.imageWidth + "px";
      }

      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Ajoute une bordure sur l'image
     * @param value nouvelle valeur de la bordure
     */
    imageBorder: function (value) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let img = div.querySelector("img");
      if (value === "non") {
        document.querySelector(".div-image-border").style.display = "none";
        img.style.border = "none";
      } else {
        document.querySelector(".div-image-border").style.display = "block";
        img.style.border = this.imageBorderWidth + "px " + this.imageBorderType + " " + this.imageBorderColor;
      }

      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Modifie l'épaisseur de la bordure de l'image
     * @param value nouvelle valeur de l'épaisseur de la bordure
     */
    imageBorderWidth: function (value) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let img = div.querySelector("img");
      this.imageBorderColor = img.style.borderColor;

      // Retirer les lettres de la valeur
      value = value.replace(/\D/g,'');
      this.imageBorderWidth = value;
      // Teste la valeur voulue par l'utilisateur
      if (value > 5) {
        this.displayToast("warning", "L'épaisseur ne doit pas dépasser 5px");
        this.imageBorderWidth = 5;
      } else if (value < 0) {
        this.displayToast("warning", "L'épaisseur ne doit pas être négative");
        this.imageBorderWidth = 0;
      } else {
        // applique les changements
        img.style.border = value + "px " + this.imageBorderType + " " + this.imageBorderColor;

        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/*******************************************
Bibliothèque d'images
*******************************************/
@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.div-images-library {
  max-width: 1044px;
  margin: 0 auto;
  padding: 0 20px;
}

.carousel {
  display: block;
  text-align: left;
  position: relative;
  margin-bottom: 22px;

  > input {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;

    &:nth-of-type(6):checked ~ .carousel__slides .carousel__slide:first-of-type {
      margin-left: -500%;
    }

    &:nth-of-type(5):checked ~ .carousel__slides .carousel__slide:first-of-type {
      margin-left: -400%;
    }

    &:nth-of-type(4):checked ~ .carousel__slides .carousel__slide:first-of-type {
      margin-left: -300%;
    }

    &:nth-of-type(3):checked ~ .carousel__slides .carousel__slide:first-of-type {
      margin-left: -200%;
    }

    &:nth-of-type(2):checked ~ .carousel__slides .carousel__slide:first-of-type {
      margin-left: -100%;
    }

    &:nth-of-type(1):checked ~ .carousel__slides .carousel__slide:first-of-type {
      margin-left: 0;
    }

    &:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) {
      box-shadow: 0 0 0 5px rgba(255, 102, 0, 0.5);
    }

    &:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) {
      box-shadow: 0 0 0 5px rgba(255, 102, 0, 0.5);
    }

    &:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) {
      box-shadow: 0 0 0 5px rgba(255, 102, 0, 0.5);
    }

    &:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) {
      box-shadow: 0 0 0 5px rgba(255, 102, 0, 0.5);
    }

    &:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) {
      box-shadow: 0 0 0 5px rgba(255, 102, 0, 0.5);
    }

    &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) {
      box-shadow: 0 0 0 5px rgba(255, 102, 0, 0.5);
    }
  }
}

.carousel__slides {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
}

.carousel__slide {
  position: relative;
  display: block;
  flex: 1 0 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 300ms ease-out;
  vertical-align: top;
  box-sizing: border-box;
  white-space: normal;

  figure {
    display: flex;
    margin: 0;
  }

  div {
    @include aspect-ratio(3, 2);
    width: 100%;
  }

  img {
    display: block;
    flex: 1 1 auto;
    object-fit: cover;
  }

  figcaption {
    align-self: flex-end;
    padding: 20px 20px 0 20px;
    flex: 0 0 auto;
    width: 25%;
    min-width: 150px;
  }

  .credit {
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.5);
    display: block;
  }

  &.scrollable {
    overflow-y: scroll;
  }
}

.carousel__thumbnails {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  margin: 0 -10px;

  .carousel__slides + & {
    margin-top: 20px;
  }

  li {
    flex: 1 1 auto;
    max-width: calc((100% / 6) - 20px);
    margin: 0 10px;
    transition: all 300ms ease-in-out;
  }

  label {
    display: block;
    @include aspect-ratio(1, 1);


    &:hover,
    &:focus {
      cursor: pointer;

      img {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
        transition: all 300ms ease-in-out;
      }
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

label img {
  max-width: 330px;
  max-height: 330px;
}

.div-image-choices input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


/*******************************************
Hauteur, largeur, verrouiller, arrondi, épaisseur
*******************************************/
#image-height {
  margin: -10px 10px 10px 0;
}

#input-image-height {
  margin-bottom: 15px;
}

.div-flex-slider {
  margin-bottom: 10px;
}

.div-lock-proportions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label-lock-proportions {
  padding-left: 210px;
}

/*******************************************
Verrouiller les proportions
*******************************************/
.btn-lock {
  display: inline-block;
  background: #ff9147;
  width: 35px;
  height: 35px;
  margin-left: 8px;
  margin-right: 8px;
  box-sizing: border-box;
  padding: 5px 0 0 10px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.btn-lock svg {
  fill: none;
  transform: translate3d(0, 0, 0);
}

.btn-lock svg .bling {
  stroke: #fff;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-dasharray: 3;
  stroke-dashoffset: 15;
  transition: all 0.3s ease;
}

.btn-lock svg .lock {
  stroke: #fff;
  stroke-width: 4;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 36;
  transition: all 0.4s ease;
}

.btn-lock svg .lockb {
  fill: #fff;
  fill-rule: evenodd;
  clip-rule: evenodd;
  transform: rotate(8deg);
  transform-origin: 14px 20px;
  transition: all 0.2s ease;
}

#inpLock {
  display: none;
}

#inpLock:checked + label {
  background: #ff6600;
}

#inpLock:checked + label svg {
  opacity: 1;
}

#inpLock:checked + label svg .bling {
  animation: bling 0.3s linear forwards;
  animation-delay: 0.2s;
}

#inpLock:checked + label svg .lock {
  stroke-dasharray: 48;
  animation: locked 0.3s linear forwards;
}

#inpLock:checked + label svg .lockb {
  transform: rotate(0);
  transform-origin: 14px 22px;
}

@-moz-keyframes bling {
  50% {
    stroke-dasharray: 3;
    stroke-dashoffset: 12;
  }
  100% {
    stroke-dasharray: 3;
    stroke-dashoffset: 9;
  }
}

@-webkit-keyframes bling {
  50% {
    stroke-dasharray: 3;
    stroke-dashoffset: 12;
  }
  100% {
    stroke-dasharray: 3;
    stroke-dashoffset: 9;
  }
}

@-o-keyframes bling {
  50% {
    stroke-dasharray: 3;
    stroke-dashoffset: 12;
  }
  100% {
    stroke-dasharray: 3;
    stroke-dashoffset: 9;
  }
}

@keyframes bling {
  50% {
    stroke-dasharray: 3;
    stroke-dashoffset: 12;
  }
  100% {
    stroke-dasharray: 3;
    stroke-dashoffset: 9;
  }
}

@-moz-keyframes locked {
  50% {
    transform: translateY(1px);
  }
}

@-webkit-keyframes locked {
  50% {
    transform: translateY(1px);
  }
}

@-o-keyframes locked {
  50% {
    transform: translateY(1px);
  }
}

@keyframes locked {
  50% {
    transform: translateY(1px);
  }
}


/*******************************************
Alignement
*******************************************/
.toolbar {
  justify-content: center;
  display: flex;
  position: relative;
  align-items: center;
  padding: 1em 1.4em;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(226, 34, 34, 0.82);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.space {
  width: 4em;
}

.icon {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 35px;
  opacity: 0.2;
  cursor: pointer;
}

.icon--left {
  align-items: flex-start;
  opacity: 0.6;
  transition: left 0.6s cubic-bezier(0.51, 0.92, 0.24, 1);
}

.icon--left .bar--short {
  position: relative;
  transition: left 0.6s cubic-bezier(0.51, 0.92, 0.24, 1);
}

.icon--center {
  align-items: center;
}

.icon--right {
  align-items: flex-end;
}

.bar {
  margin: 2px 0;
  width: 100%;
  height: 4px;
  background: black;
  border-radius: 2px;
}

.bar--short {
  width: 50%;
}


/*******************************************
Flou, contraste, luminosité
*******************************************/
.div-image-blur, .div-image-contrast, .div-image-brightness {
  display: block;
}


/*******************************************
Bordure
*******************************************/
.div-image-border {
  margin-top: 20px;
}

.div-image-border h2 {
  margin-bottom: 5px;
}

/*******************************************
Epaisseur de la bordure
*******************************************/
.div-image-border-width input {
  margin-bottom: 0;
}


/*******************************************
Sélecteur de couleurs
*******************************************/
.vacp-color-picker {
  --vacp-color-space-width: 400px;
}


/*******************************************
Type et couleur de la bordure
*******************************************/
.div-type-image-border-color {
  display: flex;
  justify-content: space-between;
}

/*******************************************
Couleur de la bordure
*******************************************/
.div-image-border-color {
  padding-top: 10px;
}

.div-image-border-color label {
  padding-left: 5px;
}

.span-align-border-color-title {
  display: flex;
  justify-content: center;
}

.span-border-color-title {
  width: 400px;
}


/*******************************************
Autres
*******************************************/
b {
  font-size: 18px;
}

b p {
  margin-bottom: 5px;
}
</style>
