<template>
<div class="div-palette">
  <span id="color-FFCC00" @click="changeColor('#FFCC00')"></span>
  <span id="color-FDAD00" @click="changeColor('#FDAD00')"></span>
  <span id="color-FF6600" @click="changeColor('#FF6600')"></span>
  <span id="color-FF9147" @click="changeColor('#FF9147')"></span>
  <span id="color-EA5D5D" @click="changeColor('#EA5D5D')"></span>
  <span id="color-E22222" @click="changeColor('#E22222')"></span>
  <span id="color-B51717" @click="changeColor('#B51717')"></span>
  <span id="color-522100" @click="changeColor('#522100')"></span>
  <span id="color-CC5200" @click="changeColor('#CC5200')"></span>
</div>
</template>

<script>
export default {
  name: "ColorPreset",

  methods: {
    /**
     * Change la couleur
     * @param color
     */
    changeColor(color) {
      this.$emit("changeColor", {color: color});
    },
  },
}
</script>

<style scoped>
.div-palette {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

span {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 20%;
  margin: 2.5px;
  box-shadow: 2px 3px 10px 0 rgba(81, 81, 81, 0.1);
  transition: 0.3s;
}

span:hover {
  cursor: pointer;
  transform: scale(1.1);
}

#color-FFCC00 {
  background-color: #FFCC00;
}
#color-FDAD00 {
  background-color: #FDAD00;
}
#color-FF6600 {
  background-color: #FF6600;
}
#color-FF9147 {
  background-color: #FF9147;
}
#color-EA5D5D {
  background-color: #EA5D5D;
}
#color-E22222 {
  background-color: #E22222;
}
#color-B51717 {
  background-color: #B51717;
}
#color-CC5200 {
  background-color: #CC5200;
}
#color-522100 {
  background-color: #522100;
}
</style>