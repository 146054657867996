<template>
  <div>
    <HeaderStyleSection headerName="Dimension" isOnTop/>
    <div class="div-separator-size">
      <!-- Hauteur du séparateur -->
      <div class="div-separator-height">
        <b><label for="separator-height">Hauteur</label></b>
        <div class="div-flex-slider">
          <input id="separator-height" v-model="separatorHeigth" max="100" min="0" name="separator-height"
                 type="range">
          <input v-model="separatorHeigth" type="text">
        </div>
      </div>
      <!-- Largeur du séparateur -->
      <div class="div-separator-width">
        <b><label for="separator-width">Largeur</label></b>
        <div class="div-flex-slider">
          <input id="separator-width" v-model="separatorWidth" max="330" min="0" name="separator-width"
                 type="range">
          <input v-model="separatorWidth" type="text">
        </div>
      </div>
    </div>
    <HeaderStyleSection headerName="Couleur"/>
    <!-- Couleurs du séparateur -->
    <div class="div-separator-color">
      <div class="color-picker">
        <ColorPicker v-model="separatorBackgroundColor" :color="separatorBackgroundColor" default-format="rgb"
                     @color-change="updateBackgroundColor">
          <template #hue-range-input-label>
            <span class="sr-only"></span>
          </template>
          <template #alpha-range-input-label>
            <span class="sr-only"></span>
          </template>
          <template #copy-button>
            <span class="sr-only">Copy color</span>
            <svg
                aria-hidden="true"
                height="15"
                viewBox="0 0 15 15"
                width="15"
                xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5 0v2H1v13h12v-3h-1v2H2V5h10v3h1V2H9V0zm1 1h2v2h3v1H3V3h3z"
                  fill="currentColor"/>
              <path
                  d="M10 7v2h5v2h-5v2l-3-3zM3 6h5v1H3zm0 2h3v1H3zm0 2h3v1H3zm0 2h5v1H3z"
                  fill="currentColor"/>
            </svg>
          </template>
          <template #format-switch-button>
            <span class="sr-only">Switch format</span>
            <svg
                aria-hidden="true"
                height="15"
                viewBox="0 0 16 15"
                width="16"
                xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8 15l5-5-1-1-4 2-4-2-1 1zm4-9l1-1-5-5-5 5 1 1 4-2z"
                  fill="currentColor"/>
            </svg>
          </template>
        </ColorPicker>
      </div>
      <div class="align-color-presets">
        <div class="color-presets">
          <ColorPreset @changeColor="changeColor"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {ColorPicker} from 'vue-accessible-color-picker';
import HeaderStyleSection from "@/components/HeaderStyleSection";
import ColorPreset from "@/components/ColorPreset";

export default {
  name: "StyleSeparateur",
  data() {
    return {
      separatorHeigth: this.getSeparatorHeigth(),
      separatorWidth: this.getSeparatorWidth(),
      separatorBackgroundColor: this.getSeparatorBackgroundColor(),
    };
  },
  components: {
    ColorPicker, HeaderStyleSection, ColorPreset
  },
  computed: {
    ...mapState({
      currentSelectedIndex: 'currentSelectedIndex',
    }),
  },
  methods: {
    /**
     * Met à jour la couleur de la bordure de l'image
     * @param payload la couleur sélectionnée
     */
    changeColor(payload) {
      this.separatorBackgroundColor = payload.color;
    },
    /**
     * Récupère la hauteur du séparateur
     * @returns {number} Hauteur du séparateur
     */
    getSeparatorHeigth() {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let separator = div.querySelector("hr");
      let style = window.getComputedStyle(separator, null).getPropertyValue('height');
      return parseInt(style);
    },
    /**
     * Récupère la largeur du séparateur
     * @returns {number} Largeur du séparateur
     */
    getSeparatorWidth() {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let separator = div.querySelector("hr");
      let style = window.getComputedStyle(separator, null).getPropertyValue('width');
      return parseInt(style);
    },
    /**
     * Met à jour la couleur du texte
     * @param eventData
     */
    updateColor(eventData) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let separator = div.querySelector("hr");

      this.color = eventData.cssColor
      separator.style.color = this.color;

      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Récupère la couleur de fond
     * @param eventData
     */
    updateBackgroundColor(eventData) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let separator = div.querySelector("hr");

      this.backgroundColor = eventData.cssColor
      separator.style.backgroundColor = this.backgroundColor;

      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Converti une couleur en hexadecimal
     * @param color couleur en RGB
     * @returns {string} couleur en hexadecimal
     */
    rgbToHex: function (color) {
      let hex = Number(color).toString(16);
      if (hex.length < 2) {
        hex = "0" + hex;
      }
      return hex;
    },
    /**
     * Converti une couleur en hexadecimal
     * @param r rouge
     * @param g vert
     * @param b bleu
     * @returns {string} couleur en hexadecimal
     */
    fullColorHex: function (r, g, b) {
      let red = this.rgbToHex(r);
      let green = this.rgbToHex(g);
      let blue = this.rgbToHex(b);
      return red + green + blue;
    },
    /**
     * Récupère la couleur du fond
     * @returns {string} couleur du fond
     */
    getSeparatorBackgroundColor() {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let separator = div.querySelector("hr");
      // Retourne la valeur RGB
      let style = window.getComputedStyle(separator, null).getPropertyValue('background-color');

      // Parse du string pour retourner seulement le code HEX
      style = style.replaceAll(' ', '');

      let styleR = style.split('(').pop().split(',')[0]
      style = style.replace('rgb(', '');
      style = style.replace(',', ';');
      style = style.replace(styleR, '');


      let styleG = style.split(';').pop().split(',')[0]
      style = style.replace(';', '');
      style = style.replace(styleG, '');

      let styleB = style.split(',').pop().split(')')[0]

      return '#' + this.fullColorHex(styleR, styleG, styleB);
    },
  },
  watch: {
    /**
     * Change l'index de la sélection
     * @param newValue nouvel index
     */
    currentSelectedIndex: function (newValue) {
      if (newValue !== this.$store.state.baseIndexValue) {
        if (document.getElementById(newValue).classList[0] === "div-hr") {
          this.separatorHeigth = this.getSeparatorHeigth();
          this.separatorWidth = this.getSeparatorWidth();
          this.separatorBackgroundColor = this.getSeparatorBackgroundColor();

          // Modifie le bit d'archivage
          this.$store.state.isModified = true;
        }
      }
    },
    /**
     * Met à jour la hauteur du séparateur
     * @param value nouvelle valeur de la hauteur
     */
    separatorHeigth(value) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let separator = div.querySelector("hr");

      // Teste la valeur voulue par l'utilisateur
      if (value > 100) {
        this.$parent.displayToast("warning", "La hauteur ne peut pas être supérieur à 100px");
        separator.style.height = 100 + "px";
        this.separatorHeigth = 100;
      } else if (value < 0) {
        this.$parent.displayToast("warning", "La hauteur du séparateur ne peut pas être négative");
        this.separatorHeigth = this.getSeparatorHeigth();
      } else {
        // Applique les changements
        separator.style.height = value + "px";

        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
    /**
     * Met à jour la largeur du séparateur
     * @param value nouvelle valeur de la largeur
     */
    separatorWidth(value) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let separator = div.querySelector("hr");

      // Teste la valeur voulue par l'utilisateur
      if (value > 330) {
        this.$parent.displayToast("warning", "La largeur du séparateur ne peut pas dépasser 330px");
        separator.style.width = 330 + "px";
        this.separatorWidth = 330;
      } else if (value < 0) {
        this.$parent.displayToast("warning", "La largeur du séparateur ne peut pas être négative");
        this.separatorWidth = 0;
      } else {
        // Applique les changements
        separator.style.width = value + "px";

        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
    /**
     * Met à jour la couleur du fond du séparateur
     * @param value nouvelle valeur de la couleur
     */
    separatorBackgroundColor(value) {
      let div = document.getElementById(this.$store.state.currentSelectedIndex);
      let separator = div.querySelector("hr");

      separator.style.backgroundColor = value;
      separator.style.border = 'none';

      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
  }
}
</script>

<style scoped>
/*******************************************
Hauteur et largeur
*******************************************/
.div-separator-height input[type="text"], .div-separator-width input[type="text"] {
  width: 50px;
  border-radius: 15%;
  padding: 0;
  margin-left: 10px;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

/*******************************************
Largeur
*******************************************/
.div-separator-width {
  margin-bottom: 20px;
}

/*******************************************
Sélecteur de couleurs
*******************************************/
.vacp-color-picker {
  --vacp-color-space-width: 400px;
}

/*******************************************
Autres
*******************************************/
b {
  font-size: 18px;
}

b p {
  margin-bottom: 5px;
}
</style>