<template>
  <span class="header-section">{{ headerName }}</span>
</template>

<script>
export default {
  name: "HeaderStyleSection",
  props: {
    headerName: String,
    isOnTop: Boolean,
  },
  methods: {
    changeCSS() {
      if (this.isOnTop) {
        const header = document.querySelector(".header-section");
        header.style.marginTop = "-10px";
      }
    }
  },
  mounted() {
    this.changeCSS();
  }
}
</script>

<style scoped>
.header-section {
  background-color: #D6D6D6F2;
  color: rgba(21, 21, 21, 0.82);
  display: block;
  margin: 15px -20px 15px -20px;
  padding: 15px;
  font-size: 1.5em;
  font-weight: 600;
}
</style>