<template>
  <!-- Croix effacement -->
  <img alt="croix-effacement" class="cross-delete" src="@/assets/images/cross-delete.png"
       @click="deleteConfirmation">
  <!-- Flèche haut -->
  <img alt="Fleche vers le haut" class="arrow-up" src="@/assets/images/arrow-up.png" @click="upElement">
  <!-- Flèche bas -->
  <img alt="Fleche vers le bas" class="arrow-down" src="@/assets/images/arrow-down.png" @click="downElement">
  <!-- SideBar -->
  <SideBar ref="form" :index="this.index" @connect="connect" @updateVar="updateVar"/>
  <div class="container" @dragleave="refreshHighlight()">
    <!-- Visualisation -->
    <div class="header-visu"><h1>Visualisation</h1></div>
    <div class="spot-visu div-visu" onclick="location.href='#ancre'"
         v-on:click='changeIndexSelected($event)' v-on:scroll="handleScroll">
    </div>
    <!-- Modification -->
    <div class="header-edit"><h1>Modification</h1></div>
    <div class="spot-edit">
      <transition>
        <StyleView/>
      </transition>
    </div>
    <!-- Code -->
    <div class="header-code"><h1>Code</h1></div>
    <div class="spot-code">
      <img alt="icone html" class="icon-html" src="@/assets/images/icon-html.png">
      <div class="full-html-code div-padding">
        <div id="first-base-html">
          <pre>{{ this.doctypeHTMLTag }}</pre>
          <pre>{{ this.firstHTMLTag }}</pre>
          <pre>{{ this.firstBodyTag }}</pre>
        </div>
        <div id="div-formated-html" class="html-code">
          <table></table>
        </div>
        <div id="second-base-html">
          <pre>{{ this.lastBodyTag }}</pre>
          <pre>{{ this.lastHTMLTag }}</pre>
        </div>
      </div>
      <div id="div-css-code" class="css-code div-padding">
        <img alt="icone css" class="icon-css" src="@/assets/images/icon-css.png">
        <table id="table-formated-css"></table>
      </div>
    </div>
    <!-- Bouton de déconnexion-->
    <div v-if="this.$store.state.userData['user'] !== undefined" class="div-disconnection">
      <button class="button-disconnect" title="Se déconnecter" @click="displayPopupRefresh"></button>
    </div>
  </div>
</template>

<script>
import StyleView from "@/views/StyleView";
import {mapState} from "vuex";
import {highlightAll} from "@/assets/highlightCode";
import SideBar from "@/views/SideBar";
import Swal from "sweetalert2";

export default {
  name: "MainHome",
  components: {SideBar, StyleView},
  data() {
    return {
      index: 0,
      firstHTMLTag: "<html>",
      lastHTMLTag: "</html>",
      firstBodyTag: "<body>",
      lastBodyTag: "</body>",
      doctypeHTMLTag: "<!DOCTYPE html>",
      addedElementsNbr: 0,
      maxElementsNbr: 9,
      scrollTop: 0,
    }
  },
  computed: {
    ...mapState({
      currentSelectedIndex: 'currentSelectedIndex',
      baseIndexValue: 'baseIndexValue',
    }),
  },
  methods: {
    /**
     * Met à jour l'index et le nombre d'éléments
     * @param payload
     */
    updateVar(payload) {
      this.index = payload.index;
      this.addedElementsNbr = payload.nbrAddedElements;
    },
    /**
     * Affiche une popup pour le refresh de la page
     */
    displayPopupRefresh() {
      Swal.fire({
        title: 'Déconnexion',
        html: `<p>Voulez-vous vous déconnectez ?<br> Toutes modifications non sauvegardées seront perdues.</p>
`,
        showCancelButton: true,
        confirmButtonColor: '#FF6600',
        cancelButtonColor: '#e22222',
        confirmButtonText: 'Se déconnecter',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
    },
    /**
     * Affiche une popup pour la connexion de l'email
     */
    displayPopupEmail() {
      Swal.fire({
        // Options de la popup
        title: 'Connexion',
        input: 'text',
        inputValue: this.$store.state.userEmail,
        inputPlaceholder: 'Email',
        text: 'Veuillez saisir votre email',
        confirmButtonText: 'Suivant',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#FF6600',
        denyButtonText: 'Annuler',
        denyButtonColor: '#e22222',
        showDenyButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          popup: 'swal2-popup-form',
        },
        showClass: {
          backdrop: 'swal2-noanimation',
          popup: '',
        },
        hideClass: {
          popup: '',
        },
      }).then((result) => {
        // Bouton mode invité
        if (result.isDenied) {
          this.$store.state.guestMode = true;
          document.getElementById('app').style = 'filter: none';
          this.$store.state.userEmail = '';

          // Bouton suivant
        } else {
          this.$store.state.userEmail = result.value;
          this.displayPopupPassword();
        }
      })
    },
    /**
     * Lance la pop up de connexion pour le mot de passe
     */
    displayPopupPassword() {
      Swal.fire({
        // options de la popup
        animation: false,
        title: 'Connexion',
        input: 'password',
        inputValue: '',
        inputPlaceholder: 'Mot de passe',
        text: this.$store.state.userEmail + '\n Veuillez saisir votre mot de passe',
        confirmButtonText: 'Connexion',
        confirmButtonColor: '#FF6600',
        denyButtonText: 'Retour',
        denyButtonColor: '#e22222',
        showDenyButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showLoaderOnConfirm: true,
        showClass: {
          backdrop: 'swal2-noanimation',
          popup: '',
        },
        preConfirm: (pwd) => {
          const email = this.$store.state.userEmail;
          const password = pwd;
          this.$store.state.passwordUser = password;

          // Promise pour connecter l'utilisateur
          let promise = this.$store.dispatch('login', {email, password})

          // Si fail de la connexion
          promise.then(function (result) {
            if (result === false) {
              Swal.showValidationMessage("Les identifiants ne sont pas valides.")
            }
          });

          return promise;
        }
      }).then((result) => {
        // Refresh la liste des elements
        this.$store.state.placedElementList = [];

        // Bouton retour
        if (result.isDenied) {
          this.displayPopupEmail();
          this.$store.state.userEmail = '';

          // Bouton connexion
        } else {
          this.$data.isConnected = true;
          // Popup de bienvenue
          Swal.fire({
            title: 'Connexion réussie',
            text: `${this.$store.state.userData['user']['username']}, Bienvenue !`,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#FF6600',
            customClass: {
              popup: 'swal2-popup-form',
            }
          })
        }
        this.$store.state.guestMode = false;
      })
    },
    /**
     * Affiche un toast
     * @param icon l'icône
     * @param title le titre
     */
    displayToast(icon, title) {
      // Toast pour informer l'utilisateur
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      })
      Toast.fire({
        icon: icon,
        title: title
      })
    },
    /**
     * Met à jour l'highlight du code
     */
    refreshHighlight() {
      // Enleve la surbrillance
      let divVisu = document.querySelector(".div-visu");
      divVisu.style.boxShadow = "0 0 0px #FF6600";
    },
    /**
     * Affiche le code css de l'élément sélectionné
     */
    displayCSSCode: function () {
      if (this.$store.state.currentSelectedIndex !== 500) {
        const tableFormatedCSS = document.getElementById("table-formated-css");
        tableFormatedCSS.innerHTML = "";
        // Récupération de l'id de l'élément
        let element = document.getElementById(this.$store.state.currentSelectedIndex);
        let elementId = element.id
        // Ajout de 1 pour ne pas avoir element_0 comme nom
        elementId++;
        // Récupération de sa classe
        let elementClass = element.className;
        // Terminer la fonction si l'élément est "undefined"
        if (!(elementClass.includes("titre") || elementClass.includes("texte") || elementClass.includes("img") || elementClass.includes("hr"))) {
          return
        }
        // Récupère son CSS
        let elementCSS = "";
        if (elementClass.includes("img")) {
          let elements = element.querySelector("img");
          elementCSS = window.getComputedStyle(elements);
        } else if (elementClass.includes("hr")) {
          let elements = element.querySelector("hr");
          elementCSS = window.getComputedStyle(elements);
        } else {
          elementCSS = window.getComputedStyle(element)
        }
        // Création d'un nom formaté de l'élément pour qu'il soit plus simple à comprendre pour l'utilisateur
        // Ajoute à une liste les styles que l'élément a en fonction de son type
        let formatedElementName;
        let listElementStyles = [];
        let colorElement = "<span style='color:#B51717'>";
        let colorProprerty = "<span style='color:#FF6600'>";

        switch (true) {
          case elementClass.includes("titre") :
            formatedElementName = colorElement + "titre_" + elementId + " </span> ";
            listElementStyles.push(colorProprerty + "font-size : </span>" + elementCSS.getPropertyValue("font-size") + ";");
            listElementStyles.push(colorProprerty + "font-family : </span>" + elementCSS.getPropertyValue("font-family") + ";");
            listElementStyles.push(colorProprerty + "text-align : </span>" + elementCSS.getPropertyValue("text-align") + ";");
            listElementStyles.push(colorProprerty + "text-decoration : </span>" + elementCSS.getPropertyValue("text-decoration") + ";");
            listElementStyles.push(colorProprerty + "font-weight : </span>" + elementCSS.getPropertyValue("font-weight") + ";");
            listElementStyles.push(colorProprerty + "font-style : </span>" + elementCSS.getPropertyValue("font-style") + ";");
            listElementStyles.push(colorProprerty + "color : </span>" + elementCSS.getPropertyValue("color") + ";");
            break;
          case elementClass.includes("texte") :
            formatedElementName = colorElement + "paragraphe_" + elementId + " </span> ";
            listElementStyles.push(colorProprerty + "font-size : </span>" + elementCSS.getPropertyValue("font-size") + ";");
            listElementStyles.push(colorProprerty + "font-family : </span>" + elementCSS.getPropertyValue("font-family") + ";");
            listElementStyles.push(colorProprerty + "text-align : </span>" + elementCSS.getPropertyValue("text-align") + ";");
            listElementStyles.push(colorProprerty + "text-decoration : </span>" + elementCSS.getPropertyValue("text-decoration") + ";");
            listElementStyles.push(colorProprerty + "font-weight : </span>" + elementCSS.getPropertyValue("font-weight") + ";");
            listElementStyles.push(colorProprerty + "font-style : </span>" + elementCSS.getPropertyValue("font-style") + ";");
            listElementStyles.push(colorProprerty + "color : </span>" + elementCSS.getPropertyValue("color") + ";");
            break;
          case elementClass.includes("hr") :
            formatedElementName = colorElement + "separateur_" + elementId + " </span> ";
            listElementStyles.push(colorProprerty + "height : </span>" + elementCSS.getPropertyValue("height") + ";");
            listElementStyles.push(colorProprerty + "width : </span>" + elementCSS.getPropertyValue("width") + ";");
            listElementStyles.push(colorProprerty + "color : </span>" + elementCSS.getPropertyValue("color") + ";");
            listElementStyles.push(colorProprerty + "background-color : </span>" + elementCSS.getPropertyValue("background-color") + ";");
            break;
          case elementClass.includes("img") :
            formatedElementName = colorElement + "image_" + elementId + " </span> ";
            listElementStyles.push(colorProprerty + "height : </span>" + elementCSS.getPropertyValue("height") + ";");
            listElementStyles.push(colorProprerty + "width : </span>" + elementCSS.getPropertyValue("width") + ";");
            listElementStyles.push(colorProprerty + "border-radius : </span>" + elementCSS.getPropertyValue("border-radius") + ";");
            listElementStyles.push(colorProprerty + "margin-left : </span>" + elementCSS.getPropertyValue("margin-left") + ";");
            listElementStyles.push(colorProprerty + "margin-right : </span>" + elementCSS.getPropertyValue("margin-right") + ";");
            listElementStyles.push(colorProprerty + "filter : </span>" + elementCSS.getPropertyValue("filter") + ";");
            listElementStyles.push(colorProprerty + "border : </span>" + elementCSS.getPropertyValue("border") + ";");
            break;
        }
        // Remplissage d'un tableau avec les styles de l'élément
        tableFormatedCSS.innerHTML += "<tr><td>#" + formatedElementName + " {</td></tr>";
        for (let i = 0; i <= listElementStyles.length - 1; i++) {
          tableFormatedCSS.innerHTML += "<tr><td></td><td>" + listElementStyles[i] + "</td></tr>";
        }
        tableFormatedCSS.innerHTML += "<tr><td>}</td></tr>";
      }
    },
    /**
     * Reprend le code HTML de la structure et le modifie pour une vue utilisateur
     */
    displayHTMLCode() {
      // Récupération des données
      const displayed = document.getElementById('div-formated-html');
      // code est un string qui regroupe l'ensemble du code html brut
      let code = document.querySelector('.spot-visu').outerHTML;
      // formatedcode lui, sera le code nettoyer afficher à l'utilisateur
      let formatedCode = '';

      // Formatage du code
      code = code.replaceAll('\n', '');
      code = code.replaceAll(/<div.*?>/ig, '');
      code = code.replaceAll(/<\/div>/ig, '');

      let i = 0;
      // Parse le code
      while (code.replaceAll('\n', '').replaceAll(' ', '') !== '') {
        // h1
        if (code.replaceAll('\n', '').replaceAll(' ', '').startsWith('<h1')) {
          code = code.replaceAll('&lt;', '"');
          code = code.replaceAll('&gt;', '"');
          code = code.replace(/<h1.*?>/, '<h1>');
          formatedCode += code.slice(0, code.indexOf('</h1>') + 5) + '\n';
          code = code.replace(/<h1.*?\/h1>/, '');
          // p
        } else if (code.replaceAll('\n', '').replaceAll(' ', '').startsWith('<p')) {
          code = code.replaceAll('&lt;', '"');
          code = code.replaceAll('&gt;', '"');
          code = code.replace(/<p.*?>/, '<p>');
          formatedCode += code.slice(0, code.indexOf('</p>') + 4) + '\n';
          code = code.replace(/<p.*?\/p>/, '');
          // img
        } else if (code.replaceAll('\n', '').replaceAll(' ', '').startsWith('<img')) {
          code = code.replace(/<img.*?src/, '<img src');
          formatedCode += code.slice(0, code.indexOf('png"') + 4) + ' ' + code.slice(code.indexOf('alt="'), code.indexOf('style') - 1) + '>' + '\n';
          code = code.replace(/<img.*?>/, '');
          // hr
        } else if (code.replaceAll('\n', '').replaceAll(' ', '').startsWith('<hr')) {
          code = code.replace(/<hr.*?>/, '<hr>');
          formatedCode += '<hr>' + '\n';
          code = code.replace('<hr>', '');
        } else if (code.replaceAll('\n', '').replaceAll(' ', '').startsWith('<section')) {
          code = code.replace(/<section.*?>/, '<section>');
        }

        // Protection contre les boucles infinieS
        i++;
        if (i > 20) {
          break;
        }
      }

      // Affichage du code
      displayed.innerText = formatedCode;

      this.selectFormatedHTML();
      // Mise en évidence
      this.updateDisplayedCode("div-formated-html", "html");
    },
    /**
     * @param match
     * @returns {string}
     */
    replace(match) {
      return match.replace(/ .*?>/, '>') + '\n';
    },
    /**
     * Replace les icones
     */
    replaceIcons() {
      // Replace la croix et les flèches
      let e = document.getElementById(this.$store.state.currentSelectedIndex)
      let cross = document.querySelector(".cross-delete");
      let upArrow = document.querySelector(".arrow-up");
      let downArrow = document.querySelector(".arrow-down");
      cross.style.top = (e.offsetTop + 5 - this.scrollTop) + "px";
      cross.style.display = "block";

      upArrow.style.top = (e.offsetTop - this.scrollTop) + "px";
      upArrow.style.display = "inline-block";

      downArrow.style.top = (e.offsetTop + 30 - this.scrollTop) + "px";
      downArrow.style.display = "inline-block";
    },
    /**
     * Fonction qui annule la sélection au scroll
     * @param event scroll
     */
    handleScroll(event) {
      this.scrollTop = event.target.scrollTop;
      if (this.currentSelectedIndex !== this.baseIndexValue) {
        this.replaceIcons();
      }
    },
    /**
     * Ajoute des couleurs pour les syntaxes
     * @param {Event} event - évènement
     */
    updateDisplayedCode: function (div, mode) {
      this.applyHighlight(div, mode);
    },
    /**
     * Execute la fonction se trouvant dans highlightCode.js
     * @param idHighlightedDiv id de la div à modifier
     */
    applyHighlight: function (idHighlightedDiv, mode) {
      highlightAll(idHighlightedDiv, mode)
    },
    /**
     * Retourne si v ou f la limite d'éléments max sur la page est atteinte.
     * @returns {boolean} si la limite est atteinte
     */
    toastLimitReached: function () {
      let nbrElements = this.addedElementsNbr;
      if (nbrElements > this.maxElementsNbr - 1) {
        this.displayToast("warning", "Nombre d'éléments maximum atteint ! (Max " + this.maxElementsNbr + ")");
        return true;
      } else {
        this.addedElementsNbr++
        return false;
      }
    },
    /**
     * Met à jour le CSS toutes les 1 seconde
     */
    autoUpdateCSSHTML: function () {
      setInterval(() => {
        // Met à jour le css seulement si un élément à changer
        if (this.$store.state.isModified) {
          this.displayCSSCode();
          this.displayHTMLCode();
          this.$store.state.isModified = false;
        }
        // Met à jour la location de la fenêtre seulement si l'index a été modifié
        if (this.$store.state.indexIsModified) {
          window.location.href = '#ancre'
          this.$store.state.indexIsModified = false;
        }
      }, 50);
    },
    /**
     * Méthode appelée lorsque l'élément est déposé
     * @param event
     */
    newElement: function (element) {
      event.preventDefault();

      let website = document.querySelector(".div-visu");

      if (!this.toastLimitReached()) {
        switch (element) {
          case "block-title":
            website.innerHTML += "<h1 id=" + this.index + " class='titre element'>Titre</h1>\n";
            this.$store.state.placedElementList.push("<h1>Titre</h1>");
            break;
          case "block-paragraph":
            website.innerHTML += "<p id=" + this.index + " class='texte element'>Paragraphe</p>\n";
            this.$store.state.placedElementList.push("<p>Paragraphe</p>");
            break;
          case "block-separator":
            website.innerHTML += "<div id=" + this.index + " class='div-hr' style=\"background-color: rgba(214, 214, 214, 0);\"><hr class='separateur element' style='width: 330px; background-color: rgb(128,128,128);'> </div>\n";
            this.$store.state.placedElementList.push("<hr>");
            break;
          case "block-image":
            website.innerHTML += "<div id=" + this.index + " class='div-img' style=\"background-color: rgba(214, 214, 214, 0);\"><img class='image element' src='images/1.png' alt='Planète Mars' style='max-width: 320px; width: 309px; height: 182px;'>  </div>\n";
            this.$store.state.placedElementList.push("<img>");
            break;
        }

        document.querySelector(".div-visu").scrollTop = document.querySelector(".div-visu").scrollHeight;

        this.$store.dispatch('setIndexSelected', this.index);
        document.getElementById(this.index).click();
        this.displayHTMLCode();
        this.displayCSSCode();

        this.index += 1;
      }
    },
    /**
     * Change l'index de l'élément sélectionné
     * @param event
     */
    changeIndexSelected: function (event) {
      const element = event.target;

      // Teste au cas où que l'élément se situe dans une div parente
      if (this.isNumeric(element.id)) {
        this.$store.dispatch('setIndexSelected', element.id);

      } else if (element.parentElement.id.length > 0) {
        this.$store.dispatch('setIndexSelected', element.parentElement.id);
      }
    },
    /**
     * Teste si le string est un chiffre valide
     * @param str string à tester
     * @returns {boolean} true si le string est un chiffre valide
     */
    isNumeric(str) {
      if (typeof str != "string") return false
      return !isNaN(str) && !isNaN(parseFloat(str))
    },
    /**
     * Highlight le code HTML de l'élément sélectionné
     */
    selectFormatedHTML() {
      const divFormatedHTML = document.getElementById("div-formated-html");
      const code = divFormatedHTML.outerText;

      let indexSelected = this.$store.state.currentSelectedIndex;
      let allElements = code.split("\n");

      divFormatedHTML.innerHTML = '';
      allElements.pop();
      allElements.forEach((line, index) => {
        line = line.replaceAll("<", "&lt;");

        if (index == indexSelected) {
          // met un span de couleur sur la ligne sélectionnée
          divFormatedHTML.innerHTML += '<span style="background-color: rgba(255,102,0,0.13); padding: 0 5px 0 5px;">' + line + '</span>' + '<br>';
        } else {
          divFormatedHTML.innerHTML += line + '<br>';
        }
      });
    },
    /**
     * Met à jour le style de l'élément sélectionné
     * @param newValue
     * @param oldValue
     */
    changeColorSelected: function (newValue, oldValue) {
      document.getElementById(newValue).style.backgroundColor = "rgba(255,102,0,0.25)";
      if (oldValue !== this.baseIndexValue) {
        if (oldValue != (this.$store.state.placedElementList.length)) {
          document.getElementById(oldValue).style.backgroundColor = "rgba(214, 214, 214, 0)";
        }
      }
    },
    /**
     * Demande à l'utilisateur s'il veut vraiment supprimer l'élément
     */
    deleteConfirmation: function () {
      Swal.fire({
        title: 'Attention',
        text: "L'élément sélectionné sera supprimé",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonColor: '#FF6600',
        cancelButtonColor: '#e22222',
        confirmButtonText: 'Oui, l\'effacer !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteElement();
        }
      })
    },
    /**
     * Efface l'élément sélectionné quand on appuie sur le bouton oui
     */
    deleteElement: function () {
      // Efface le contenu de l'affichage CSS
      const tableFormatedCSS = document.getElementById("table-formated-css");
      tableFormatedCSS.innerHTML = "";

      this.$store.state.placedElementList.splice(0, 1);

      // Supprimer l'élément sélectionné
      document.getElementById(this.$store.state.currentSelectedIndex).remove();

      // Remet à jour les index des elements
      document.querySelectorAll(".element").forEach(element => {
        if (element.classList[0] === "image" || element.classList[0] === "separateur") {
          element = element.parentElement;
        }
        if (element.id > this.$store.state.currentSelectedIndex) {
          element.id -= 1;
        }
      });
      document.querySelector(".cross-delete").style.display = "none";
      document.querySelector(".arrow-up").style.display = "none";
      document.querySelector(".arrow-down").style.display = "none";
      this.updateIndex(this.$store.state.placedElementList.length);
      this.$store.state.currentSelectedIndex = this.baseIndexValue;
      // Retire de 1 le nombre d'éléments présents sur la page
      this.addedElementsNbr--
      this.displayHTMLCode();
    },
    /**
     * Monte l'élément sélectionné
     */
    upElement: function () {
      if (document.getElementById(this.$store.state.currentSelectedIndex).previousElementSibling != null) {

        let el1 = document.getElementById(this.$store.state.currentSelectedIndex);
        let el2 = el1.previousElementSibling;

        let tempID1 = el1.id;
        let tempID2 = el2.id;

        // Inverse les 2 id
        el1.removeAttribute('id');
        el2.removeAttribute('id');

        el1.setAttribute('id', tempID2);
        el2.setAttribute('id', tempID1);

        // Inverse les 2 elements
        el1.insertAdjacentElement('afterend', el2);

        this.replaceIcons();

        this.$store.dispatch('setIndexSelected', this.$store.state.currentSelectedIndex - 1);

        this.displayHTMLCode();
        this.displayCSSCode();
      } else {
        this.displayToast("error", "Impossible de monter l'élément");
      }
    },
    /**
     * Descend l'élément sélectionné
     */
    downElement: function () {
      if (document.getElementById(this.$store.state.currentSelectedIndex).nextElementSibling != null) {

        let el1 = document.getElementById(this.$store.state.currentSelectedIndex);
        let el2 = el1.nextElementSibling;

        let tempID1 = el1.id;
        let tempID2 = el2.id;

        // Inverse les 2 id
        el1.removeAttribute('id');
        el2.removeAttribute('id');

        el1.setAttribute('id', tempID2);
        el2.setAttribute('id', tempID1);

        // Inverse les 2 elements
        el1.insertAdjacentElement('beforebegin', el2);

        this.replaceIcons();

        this.$store.dispatch('setIndexSelected', parseFloat(this.$store.state.currentSelectedIndex) + 1);

        this.displayHTMLCode();
        this.displayCSSCode();
      } else {
        this.displayToast("error", "Impossible de descendre l'élément");
      }
    },
    /**
     * Met à jour l'index
     * @param i le nouvel index
     */
    updateIndex(i) {
      this.index = i;
    },
  },
  watch: {
    /**
     * Code effectué quand l'index change
     * @param newValue la nouvelle valeur
     * @param oldValue l'ancienne valeur
     */
    currentSelectedIndex: function (newValue, oldValue) {
      newValue = parseInt(newValue);
      if (newValue !== this.baseIndexValue) {
        this.replaceIcons();

        if (newValue !== oldValue) {
          this.changeColorSelected(newValue, oldValue);
        }
      }
      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
      this.$store.state.indexIsModified = true;
      this.displayHTMLCode();
    },
  },
  beforeMount() {
    // Propose une déconnexion au F5
    window.addEventListener("keydown", event => {
      if (event.keyCode == 116) {
        event.preventDefault();
        this.displayPopupRefresh();
      }

      // Propose de supprimer un élément quand on appuie sur la touche Delete
      if (event.key === "Delete") {
        if (this.$store.state.currentSelectedIndex !== this.baseIndexValue)
          this.deleteConfirmation();
      }
    });
  },
  mounted() {
    // Affiche le site contenu dans la base
    if (this.$store.state.userData['user'] !== undefined) {
      let webSiteCode = this.$store.state.userData.user['website'];
      if (webSiteCode !== null && webSiteCode.length > 10) {
        this.$refs.form.refreshPage(webSiteCode);
        this.displayHTMLCode();
      }
    }
    this.applyHighlight("first-base-html", "html");
    this.applyHighlight("second-base-html", "html");
    this.autoUpdateCSSHTML();
  },
}
</script>

<style>
/******************************************
Transition
******************************************/
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


/*******************************************
Général
*******************************************/
html, body, .container, #app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Chakra Petch, sans-serif;
}

body {
  background-image: url("../assets/images/login-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  user-select: none;
}

h1 {
  margin-top: 0;
}


.spot-visu .div-img, .spot-visu h1, .spot-visu p {
  padding: 10px;
}

.div-padding {
  padding: 10px;
}

span {
  width: 100%;
}

hr {
  border: none;
  height: 2px;
}


/*******************************************
Bouton de déconnexion
*******************************************/
.div-disconnection {
  z-index: 1;
  position: absolute;
  top: 5px;
  right: 5px;
}

.button-disconnect {
  background-image: url("@/assets/images/logout.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: none;
}

.button-disconnect:hover {
  background-color: rgba(226, 34, 34, 0.45);
  cursor: pointer;
}

/*******************************************
Area
*******************************************/
.container {
  width: auto;
  height: 100%;
  display: grid;
  transition: 0.5s;
  align-items: center;
  grid-template-columns: 250px 418px 600px 600px;
  grid-template-rows: 50px 1fr;
  gap: 0 3.35%;
  grid-auto-flow: column;
  justify-content: flex-end;
  grid-template-areas:
    "header-block header-visu header-edit header-code"
    "spot-block spot-visu spot-edit spot-code"
    "spot-block spot-visu spot-edit spot-code"
    "spot-block spot-visu spot-edit spot-code";
}

.header-visu {
  grid-area: header-visu;
  padding: 0 16px 0 16px;
}

.spot-edit, .spot-visu, .header-visu, .header-edit {
  border-right: 1px solid black
}

.spot-edit, .spot-visu, .spot-code, .header-code, .header-visu, .header-edit {
  border-left: 1px solid black
}

.header-edit, .header-visu, .header-code {
  box-shadow: 0 15px 12px -15px #2c3e50;
  border-bottom: 1px solid black;
  background-color: #C4C4C4;
  color: black;
  z-index: 1;
  text-align: center;
  font-weight: 450;
  font-size: 1.2em;
}

.spot-edit, .spot-visu, .spot-code {
  background-color: white;
}

.spot-visu {
  grid-area: spot-visu;
  word-break: break-word;
  overflow-x: auto;
  scrollbar-width: none;
  padding: 0 16px 0 16px;
}

.header-edit {
  grid-area: header-edit;

}

.spot-edit {
  grid-area: spot-edit;
  overflow-x: auto;
  padding-top: 0px;
  overflow-x: hidden;
}

.spot-edit h2 {
  padding-left: 10px;
}

.header-code {
  grid-area: header-code;
}

.spot-code {
  grid-area: spot-code;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 18px;
}

#table-formated-css {
  width: 100%;
  height: 60%;
  border-collapse: collapse;
  display: block;
}

.spot-edit, .spot-code, .header-edit, .header-code {
  height: 100%;
  width: 100%;
  display: inline-block;
}

.header-visu, .spot-visu {
  height: 100%;
  display: inline-block;
}

.en-tete p {
  margin: 0;
}


/*******************************************
Emplacement visualisation
*******************************************/
.div-flex-colonnes {
  display: flex;
}

.div-flex-left-colonne, .div-flex-right-colonne {
  width: 332px;
  min-height: 50px;
  border: red solid 1px;
}


/*******************************************
Toast
*******************************************/
body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(15px);
  position: absolute;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
  color: white;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
  color: white;
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
  color: white;
}


.swal2-popup {
  font-family: Chakra Petch, serif;
}


/*******************************************
Code CSS, HTML
*******************************************/
.css-code table {
  margin-left: 0;
}

.full-html-code, .css-code {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 48%;
  height: 48%;
}

.html-code {
  padding-left: 20px;
  padding-bottom: 10px;
  word-break: break-word;
}

.css-code {
  border-top: solid 1px #9a9797;
}

.icon-css, .icon-html {
  width: 50px;
  height: 50px;
}

.icon-css {
  position: relative;
  float: right;
  padding: 5px;
}

#first-base-html, #second-base-html {
  line-height: 1.5px;
}

#first-base-html {
  padding-top: 10px;
}

td:nth-child(1) {
  width: 15px;
  display: block ruby;
}


td:nth-child(2) {
  word-wrap: break-word;
}

table {
  width: 100%;
  margin-left: 2vw;
}

td {
  max-height: 30px;
}

pre {
  margin-top: 5px;
}

tbody::before {
  content: '';
  display: block;
  height: 8px;

}

/*******************************************
Croix effacement, fleche haut, fleche bas
*******************************************/
.cross-delete, .arrow-up, .arrow-down {
  height: 25px;
  width: 25px;
  display: none;
  position: absolute;
  cursor: pointer;

}

.cross-delete {
  left: 595px;
}

.arrow-down, .arrow-up {
  left: 145px;
}

/*******************************************
Image
*******************************************/
.image {
  max-width: 330px;
  max-height: 330px;
  pointer-events: none;
}

img {
  display: block;
}

.icon-html {
  position: relative;
  float: right;
  padding: 15px 10px 5px 5px;
}

/*******************************************
Séparateur
*******************************************/
.div-hr {
  padding: 1px 10px 1px 10px;
  margin-bottom: 10px;
}

.div-hr img {
  position: relative;
  bottom: 20px;
}
</style>
