<template>
  <div class="login">
    <div class="logo-container">
      <img alt="logo de google mars" class="logo" src="../../public/logo.png">
    </div>
    <div class="login-form">
      <h2> Veuillez vous connecter </h2>
      <form>
        <ul>
          <li>
            <input id="user-email" v-model="mail" autofocus name="mail"
                   placeholder="E-mail" required type="email" @keyup.enter="this.login(this.mail, this.mdp)">
          </li>
          <li>
            <input id="user-pass" v-model="mdp" name="password" placeholder="Mot de passe"
                   required type="password" @keyup.enter="this.login(this.mail, this.mdp)">
          </li>
          <li class="container-button">
            <button class="btn-login" type="button" @click="this.login(this.mail, this.mdp)">Se connecter</button>
            <button class="btn-guest" type="button" @click="this.$store.state.userLogedOrGuest = true;">Mode invité
            </button>
          </li>
        </ul>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginMain',
  data() {
    return {
      mail: "",
      mdp: "",
    }
  },
  methods: {
    login(email, password) {
      this.$store.dispatch('login', {
        "email": email,
        "password": password,
      })
    },
  },
};
</script>

<style scoped>
.login {
  font-family: "Chakra Petch", sans-serif;
  background-image: url("../assets/images/login-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login h2 {
  font-size: 3em;
  margin: .25em 0;
  font-weight: bold;
  color: #FFFFFF;
}

.logo-container img {
  width: 150px;
  height: 150px;
}

.login-form {
  padding: 1em;
  margin-top: 1em;
  border-radius: 15px;
  position: relative;
  background: transparent;
}

.login-form ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.login-form ul li {
  display: flex;
  flex-direction: column;
}

.login-form label {
  display: inline-block;
  text-align: left;
  margin-bottom: 5px;
}

.login-form input {
  font-size: 1.125em;
  background: #FFFFFF;
  border: none;
  height: 2.625em;
  padding: 0 .75em;
  border-radius: 10px;
  margin-bottom: 1.75em;
  color: rgba(0, 0, 0, 0.75);
}

.login-form input:focus {
  outline: 2px solid #FF9147;
}

.container-button {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
}

.login-form button {
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
  color: #FFFFFF;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
  width: 45%;
}

.login-form .btn-login {
  background-color: #79B159;

}

.login-form .btn-guest {
  background-color: #EA5D5D;
}

.login-form a {
  margin-top: 1em;
  color: #D6D6D6;
}
</style>