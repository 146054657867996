<template>
  <LoginMain />
</template>

<script>
import LoginMain from "@/components/LoginMain";
export default {
  name: "LoginView",
  components: {
    LoginMain
  }
}
</script>

<style scoped>
</style>