<template>
  <h2 v-if="SelectedElementType===''">Sélectionner un élément pour le modifier</h2>
  <div v-else-if="SelectedElementType==='titre'" class="div-padding">
    <StyleTexte/>
  </div>
  <div v-else-if="SelectedElementType==='texte'" class="div-padding">
    <StyleTexte/>
  </div>
  <div v-else-if="SelectedElementType==='div-hr'" class="div-padding">
    <StyleSeparateur/>
  </div>
  <div v-else-if="SelectedElementType==='div-img'" class="div-padding">
    <StyleImage/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import StyleTexte from "@/components/Style/StyleTexte";
import StyleSeparateur from "@/components/Style/StyleSeparateur";
import StyleImage from "@/components/Style/StyleImage";

export default {
  name: "StyleView",
  components: {StyleImage, StyleSeparateur, StyleTexte},
  data() {
    return {
      SelectedElementType: "",
    }
  },
  computed: {
    ...mapState({
      currentSelectedIndex: 'currentSelectedIndex',
    }),
  },
  methods: {
    displayToast(icon, title) {
      this.$parent.displayToast(icon, title)
    },
    /**
     * Affiche le code CSS
     */
    displayCSSCode() {
      this.$parent.displayCSSCode();
    },
    /**
     * Affiche le code HTML
     */
    displayHTMLCode() {
      this.$parent.displayHTMLCode();
    },
  },
  watch: {
    /**
     * Sélectionne l'index
     * @param newValue le nouvel index
     */
    currentSelectedIndex: function (newValue) {
      this.SelectedElementType = newValue === this.$store.state.baseIndexValue ? "" : document.getElementById(newValue).classList[0];
    },
  }
}
</script>

<style scoped>

/**
:deep Pour que les enfants puissent utiliser le style
 */
:deep(.color-picker) {
  display: flex;
  justify-content: center;
}

:deep(.div-flex-slider) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*******************************************
Input type text
*******************************************/
:deep(input[type="text"]:not(.vacp-color-input)) {
  width: 50px;
  border-radius: 15%;
  padding: 0;
  margin-left: 10px;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}


/*******************************************
Input type range
*******************************************/
:deep(input[type="range"]:not(.vacp-range-input)) {
  -webkit-appearance: none;
  background: transparent;
  width: 90%;
  max-width: 500px;
  outline: none;
  height: 30px;
  margin-right: 10px;
}

:deep(input[type="range"]:not(.vacp-range-input):focus),
:deep(input[type="range"]:not(.vacp-range-input):active),
:deep(input[type="range"]:not(.vacp-range-input)::-moz-focus-inner),
:deep(input[type="range"]:not(.vacp-range-input)::-moz-focus-outer) {
  border: 0;
  outline: none;
}

:deep(input[type="range"]:not(.vacp-range-input)::-moz-range-thumb) {
  border: none;
  height: 25px;
  width: 25px;
  background-color: transparent;
  background-image: url("../assets/images/planet-mars.png");
  background-position: 0 0;
  background-size: cover;
  cursor: pointer;
}

:deep(input[type="range"]:not(.vacp-range-input)::-moz-range-thumb:active) {
  background-position: 100% 0;
}

:deep(input[type="range"]:not(.vacp-range-input)::-moz-range-track) {
  width: 100%;
  height: 10px;
  background: #eee;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

:deep(input[type="range"]:not(.vacp-range-input)::-moz-range-progress) {
  height: 12px;
  background: linear-gradient(to bottom right, #FF6600, rgba(226, 34, 34, 0.82));
  border-radius: 10px;
  cursor: pointer;
}

:deep(input[type="range"]:not(.vacp-range-input)::-webkit-slider-thumb) {
  border: none;
  height: 50px;
  width: 50px;
  background-color: transparent;
  background-image: url("../assets/images/planet-mars.png");
  background-position: 0 0;
  background-size: cover;
  cursor: pointer;
  margin-top: -15px;
  -webkit-appearance: none;
}

:deep(input[type="range"]:not(.vacp-range-input)::-webkit-slider-thumb:active) {
  background-position: 100% 0;
}

:deep(input[type="range"]:not(.vacp-range-input)::-webkit-slider-runnable-track) {
  width: 100%;
  height: 20px;
  background: #eee;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  -webkit-appearance: none;
}


/*******************************************
Input type checkbox
*******************************************/
:deep(input[type="checkbox"]) {
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  margin: 0 5px 0 0;
}

:deep(input[type="checkbox"]::before) {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em rgba(226, 34, 34, 0.82);
}

:deep(input[type="checkbox"]:checked::before) {
  transform: scale(1);
}

:deep(input[type="checkbox"]:hover) {
  cursor: pointer;
}

/*******************************************
Sélecteur de couleurs
*******************************************/
:deep(.sr-only) {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

:deep(.align-color-presets) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

:deep(.color-presets) {
  width: 400px;
}
</style>