<template>
  <MainHome/>
</template>

<script>
import MainHome from "@/components/HomeMain";

export default {
  name: "LoginView",
  components: {
    MainHome,
  }
}
</script>

<style scoped>
</style>