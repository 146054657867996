<template>
  <div>
    <HeaderStyleSection headerName="Contenu" isOnTop/>
    <!-- Contenu -->
    <div class="div-text-content">
      <textarea id="text-content" v-model="textContent" cols="60" maxlength="150" name="text-content" rows="5"
                wrap="hard">Mon texte</textarea>
    </div>
    <div id="div-info-character">
      <span id="textarea-current">{{ currentCharacter }}</span>
      <span id="textarea-maximum">/ 150</span>
    </div>
    <HeaderStyleSection headerName="Mise en forme" isOnTop/>
    <div class="div-text-style-column-1">
      <!-- Police -->
      <div class="div-text-font">
        <label class="select" for="text-font">
          <select id="text-font" v-model="textFont" name="text-font">
            <option disabled hidden selected value="1">Police</option>
            <option>Chakra Petch</option>
            <option>Roboto</option>
            <option>Gabriola</option>
            <option>Times New Roman</option>
            <option>Arial</option>
            <option>Lucida Console</option>
          </select>
          <svg>
            <use xlink:href="#select-arrow-down"></use>
          </svg>
        </label>
        <svg class="sprites">
          <symbol id="select-arrow-down" viewbox="0 0 10 6">
            <polyline points="1 1 5 5 9 1"></polyline>
          </symbol>
        </svg>
      </div>
      <!-- Alignement -->
      <div class="div-text-alignment">
        <div class="toolbar">
          <div class="icon icon--left" @click="alignementButtonComportment(1)">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar bar--short"></div>
          </div>
          <div class="space"></div>
          <div class="icon icon--center" @click="alignementButtonComportment(2)">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar bar--short"></div>
          </div>
          <div class="space"></div>
          <div class="icon icon--right" @click="alignementButtonComportment(3)">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar bar--short"></div>
          </div>
        </div>
      </div>
      <!-- Accentuation -->
      <div class="div-text-accentuation">
        <div class="checkbox">
          <input id="underline" v-model="textAccentuation" name="text-accentuation" type="checkbox" value="underline">
          <label class="underline" for="underline">S</label>
        </div>
        <div class="checkbox">
          <input id="bold" v-model="textAccentuation" name="text-accentuation" type="checkbox" value="bold">
          <label class="bold" for="bold"><strong>G</strong></label>
        </div>
        <div class="checkbox">
          <input id="italic" v-model="textAccentuation" name="text-accentuation" type="checkbox" value="italic">
          <label class="italic" for="italic"><em>I</em></label>
        </div>
      </div>

    </div>
    <!-- Taille texte -->
    <div class="div-text-size">
      <b><label for="separator-height">Taille du texte</label></b>
      <div class="div-flex-slider">
        <input id="text-size" v-model="textSize" max="40" min="16" name="text-size" type="range">
        <input v-model="textSize" type="text">
      </div>
    </div>
    <HeaderStyleSection headerName="Couleur"/>
    <!-- Couleur -->
    <div class="color-picker">
      <ColorPicker v-model="textColor" :color="textColor" default-format="rgb"
                   @color-change="updateColor">
        <template #hue-range-input-label>
          <span class="sr-only"></span>
        </template>
        <template #alpha-range-input-label>
          <span class="sr-only"></span>
        </template>
        <template #copy-button>
          <span class="sr-only">Copy color</span>
          <svg
              aria-hidden="true"
              height="15"
              viewBox="0 0 15 15"
              width="15"
              xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 0v2H1v13h12v-3h-1v2H2V5h10v3h1V2H9V0zm1 1h2v2h3v1H3V3h3z"
                fill="currentColor"/>
            <path
                d="M10 7v2h5v2h-5v2l-3-3zM3 6h5v1H3zm0 2h3v1H3zm0 2h3v1H3zm0 2h5v1H3z"
                fill="currentColor"/>
          </svg>
        </template>
        <template #format-switch-button>
          <span class="sr-only">Switch format</span>
          <svg
              aria-hidden="true"
              height="15"
              viewBox="0 0 16 15"
              width="16"
              xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 15l5-5-1-1-4 2-4-2-1 1zm4-9l1-1-5-5-5 5 1 1 4-2z"
                fill="currentColor"/>
          </svg>
        </template>
      </ColorPicker>
    </div>
    <div class="align-color-presets">
      <div class="color-presets">
        <ColorPreset @changeColor="changeColor"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {ColorPicker} from 'vue-accessible-color-picker';
import HeaderStyleSection from "@/components/HeaderStyleSection";
import ColorPreset from "@/components/ColorPreset";
import Swal from "sweetalert2";

export default {
  name: "StyleTexte",
  data: function () {
    return {
      textContent: document.getElementById(this.$store.state.currentSelectedIndex).outerText,
      currentCharacter: 0,
      textSize: this.getTextSize(),
      textAlignment: this.getTextAlignment(),
      textAccentuation: this.getTextAccentuation(),
      textColor: this.getTextColor(),
      textFont: this.getTextFont(),
    }
  },
  components: {
    ColorPicker, HeaderStyleSection, ColorPreset
  },
  computed: {
    ...mapState({
      currentSelectedIndex: 'currentSelectedIndex',
    }),
  },
  methods: {
    /**
     * Met à jour la couleur de la bordure de l'image
     * @param payload la couleur sélectionnée
     */
    changeColor(payload) {
      this.textColor = payload.color;
    },
    alignementButtonComportment(button) {
      document.querySelectorAll('.icon').forEach((elem, i) => {
        elem.onclick = () => {
          const toolbarLeft = document.querySelector('.toolbar').getBoundingClientRect().left;
          const elemLeft = elem.getBoundingClientRect().left;
          document.documentElement.style.setProperty('--icon', `${elemLeft - toolbarLeft}px`);
          document.documentElement.style.setProperty('--bar', `${(i - 1) * 25}%`);
        };
      });

      let divLeft = document.querySelector('.icon--left');
      let divCenter = document.querySelector('.icon--center');
      let divRight = document.querySelector('.icon--right');
      if (divLeft !== null && divCenter !== null && divRight !== null) {
        divLeft.style.opacity = 0.2;
        divCenter.style.opacity = 0.2;
        divRight.style.opacity = 0.2;
      }

      switch (button) {
        case 1:
          this.textAlignment = 'gauche';
          if (divLeft !== null) {
            divLeft.style.opacity = 0.6;
          }
          break;
        case 2:
          this.textAlignment = 'centre';
          if (divCenter !== null) {
            divCenter.style.opacity = 0.6;
          }
          break;
        case 3:
          this.textAlignment = 'droite';
          if (divRight !== null) {
            divRight.style.opacity = 0.6;
          }
          break;
      }
    },
    /**
     * Affiche un toast
     * @param icon l'icône
     * @param title le titre
     */
    displayToast(icon, title) {
      // Toast pour informer l'utilisateur
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      })
      Toast.fire({
        icon: icon,
        title: title
      })
    },
    /**
     * Obtient la police du texte
     * @returns {string}
     */
    getTextFont() {
      let e = document.getElementById(this.$store.state.currentSelectedIndex)
      let style = window.getComputedStyle(e, null).getPropertyValue('font-family');
      if (style === 'Chakra Petch, sans-serif') {
        return 'Chakra Petch';
      } else {
        return style;
      }
    },

    /**
     * Récupère la taille du texte
     * @returns {number} taille du texte
     */
    getTextSize: function () {
      let e = document.getElementById(this.$store.state.currentSelectedIndex)
      let style = window.getComputedStyle(e, null).getPropertyValue('font-size');
      return parseInt(style);
    },
    /**
     * Récupère l'alignement du texte
     * @returns {string} alignement du texte
     */
    getTextAlignment: function () {
      let e = document.getElementById(this.$store.state.currentSelectedIndex)
      let style = window.getComputedStyle(e, null).getPropertyValue('text-align');

      switch (style) {
        case "start":
          this.alignementButtonComportment(1);
          return "gauche";
        case "center":
          this.alignementButtonComportment(2);
          return "centre";
        case "right":
          this.alignementButtonComportment(3);
          return "droit";
      }
      return "gauche";
    },
    /**
     * Récupère l'accentuation du texte
     * @returns {*[]} accentuation du texte (souligné, gras, italique)
     */
    getTextAccentuation: function () {
      let e = document.getElementById(this.$store.state.currentSelectedIndex)
      let accentuation = [];

      let style = window.getComputedStyle(e, null).getPropertyValue('text-decoration');
      if (style.includes("underline")) {
        accentuation.push("underline");
      }

      style = window.getComputedStyle(e, null).getPropertyValue('font-weight');
      // 700 est la valeur donnée à bold
      if (style == 700) {
        accentuation.push("bold");
      }

      style = window.getComputedStyle(e, null).getPropertyValue('font-style');
      if (style === "italic") {
        accentuation.push("italic");
      }

      return accentuation;
    },
    /**
     * Récupère la couleur du texte
     * @returns {string} couleur du texte
     */
    getTextColor: function () {
      let e = document.getElementById(this.$store.state.currentSelectedIndex)
      // Retourne la valeur RGB
      let style = window.getComputedStyle(e, null).getPropertyValue('color');

      // Parse du string pour retourner seulement le code HEX
      style = style.replaceAll(' ', '');

      let styleR = style.split('(').pop().split(',')[0]
      style = style.replace('rgb(', '');
      style = style.replace(',', ';');
      style = style.replace(styleR, '');


      let styleG = style.split(';').pop().split(',')[0]
      style = style.replace(';', '');
      style = style.replace(styleG, '');

      let styleB = style.split(',').pop().split(')')[0]

      return '#' + this.fullColorHex(styleR, styleG, styleB);
    },
    /**
     * Met à jour la couleur du texte
     * @param eventData
     */
    updateColor(eventData) {
      this.color = eventData.cssColor
      document.getElementById(this.$store.state.currentSelectedIndex).style.color = this.color;

      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Converti une couleur en hexadecimal
     * @param color couleur en RGB
     * @returns {string} couleur en hexadecimal
     */
    rgbToHex: function (color) {
      let hex = Number(color).toString(16);
      if (hex.length < 2) {
        hex = "0" + hex;
      }
      return hex;
    },

    /**
     * Converti une couleur en hexadecimal
     * @param r rouge
     * @param g vert
     * @param b bleu
     * @returns {string} couleur en hexadecimal
     */
    fullColorHex: function (r, g, b) {
      let red = this.rgbToHex(r);
      let green = this.rgbToHex(g);
      let blue = this.rgbToHex(b);
      return red + green + blue;
    },
  },
  watch: {
    /**
     * Change l'index de la sélection
     * @param newValue nouvelle valeur de l'index
     */
    currentSelectedIndex: function (newValue) {
      if (newValue !== this.$store.state.baseIndexValue) {
        // Si l'index actuel est un texte ou un titre
        if (document.getElementById(newValue).classList[0] === "texte" || document.getElementById(newValue).classList[0] === "titre") {
          this.textContent = document.getElementById(this.$store.state.currentSelectedIndex).outerText;
          this.textSize = this.getTextSize();
          this.textAlignment = this.getTextAlignment();
          this.textAccentuation = this.getTextAccentuation();
          this.textColor = this.getTextColor();
          this.textFont = this.getTextFont();

          setTimeout(() => {
            this.currentCharacter = document.getElementById('text-content').value.length;
          }, 100);

          // Modifie le bit d'archivage
          this.$store.state.isModified = true;
        }
      }
    },
    /**
     * Met à jour le texte
     * @param value nouvelle valeur du texte
     */
    textContent: function (value) {
      document.getElementById(this.$store.state.currentSelectedIndex).innerText = value;

      this.currentCharacter = document.getElementById('text-content').value.length;

      // Mise à jour des valeurs du code html en modifiant le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Met à jour la taille du texte
     * @param value nouvelle valeur de la taille
     */
    textSize: function (value) {
      // Teste la valeur voulue par l'utilisateur
      if (value > 40) {
        this.displayToast("warning", "La taille du texte ne peut pas dépasser 40px");
        this.textSize = 40;
      } else if (value < 16) {
        if (value < 0) {
          this.displayToast("warning", "La taille du texte ne peut pas être négative");
          this.textSize = 0;
        }
      } else if (value/value !== 1) {
        this.displayToast("warning", "Valeur doit être un nombre");
        this.textSize = 20;

      } else {
        // Applique les changements
        document.getElementById(this.$store.state.currentSelectedIndex).style.fontSize = value + "px";

        // Modifie le bit d'archivage
        this.$store.state.isModified = true;
      }
    },
    /**
     * Met à jour l'alignement du texte
     * @param value nouvelle valeur de l'alignement
     */
    textAlignment: function (value) {
      switch (value) {
        case "gauche":
          document.getElementById(this.$store.state.currentSelectedIndex).style.textAlign = "left";
          break;
        case "centre":
          document.getElementById(this.$store.state.currentSelectedIndex).style.textAlign = "center";
          break;
        case "droite":
          document.getElementById(this.$store.state.currentSelectedIndex).style.textAlign = "right";
          break;
      }
      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Met à jour l'accentuation du texte
     * @param value nouvelle valeur de l'accentuation
     */
    textAccentuation: function (value) {
      let e = document.getElementById(this.$store.state.currentSelectedIndex)
      e.style.textDecoration = "none";
      e.style.fontWeight = "normal";
      e.style.fontStyle = "normal";

      value.forEach(element => {
        switch (element) {
          case "underline":
            e.style.textDecoration = "underline";
            break;
          case "bold":
            e.style.fontWeight = "bold";
            break;
          case "italic":
            e.style.fontStyle = "italic";
            break;
        }
      });
      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
    /**
     * Met à jour la police du texte
     * @param value nouvelle valeur de la police
     */
    textFont: function (value) {
      document.getElementById(this.$store.state.currentSelectedIndex).style.fontFamily = value;
      // Modifie le bit d'archivage
      this.$store.state.isModified = true;
    },
  },
}
</script>

<style scoped>
/*******************************************
Zone de texte, police, accentuation et alignement
*******************************************/
fieldset, .div-text-font, .div-text-accentuation, .div-text-alignment {
  display: inline-block;
}


/*******************************************
Colonne 1
*******************************************/
.div-text-style-column-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -15px;
}


/*******************************************
Zone de texte
*******************************************/

#div-info-character {
  float: right;
  padding: 0.1rem 0 0 0;
  font-size: 0.875rem;
  margin-top: -10px;
  margin-right: 9px;
  opacity: 0.3;
}

fieldset {
  border: 1px solid rgba(226, 34, 34, 0.82);
  padding-top: 0;
  padding-bottom: 0;
}

#text-content {
  margin-bottom: 10px;
  resize: none;
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid rgba(226, 34, 34, 0.82);
  width: 94.5%;
}

textarea:focus {
  outline: none !important;
  border-color: rgba(226, 34, 34, 0.82);
  box-shadow: 0 0 5px rgba(226, 34, 34, 0.82);
}


/*******************************************
Police
*******************************************/
.select {
  position: relative;
  min-width: 200px;
}

.select svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  stroke-width: 2px;
  stroke: #FF6600;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
}

.select select {
  -webkit-appearance: none;
  padding: 7px 40px 7px 12px;
  width: 100%;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px -2px #FF6600;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
}

.select select:required:invalid {
  color: #FF6600;
}

.select select option {
  color: #000000;
}

.select select option[value=""][disabled] {
  display: none;
}

.select select:focus {
  outline: none;
  border-color: #FF6600;
  box-shadow: 0 0 0 2px rgba(255, 102, 0, 0.2);
}

.select select:hover + svg {
  stroke: #FF6600;
}

.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}


/*******************************************
Alignement
*******************************************/
.div-text-alignment {
  height: 3.5em;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 17px;
}

.toolbar {
  justify-content: center;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.4em 1em;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(226, 34, 34, 0.82);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.space {
  width: 2em;
}

.icon {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 28px;
  opacity: 0.2;
  cursor: pointer;
}

.icon--left {
  align-items: flex-start;
  opacity: 0.6;
  transition: left 0.6s cubic-bezier(0.51, 0.92, 0.24, 1);
}

.icon--left .bar--short {
  position: relative;
  transition: left 0.6s cubic-bezier(0.51, 0.92, 0.24, 1);
}

.icon--center {
  align-items: center;
}

.icon--right {
  align-items: flex-end;
}

.bar {
  margin: 2px 0;
  width: 100%;
  height: 4px;
  background: black;
  border-radius: 2px;
}

.bar--short {
  width: 50%;
}


/*******************************************
Accentuation
*******************************************/
.div-text-accentuation label {
  font-size: 1.2em;
  margin-left: 2px;
  margin-right: 10px;
}

.underline {
  text-decoration: underline;
}

.checkbox {
  display: inline-block;
  margin: 0 5px;
}


/*******************************************
Taille du texte
*******************************************/

.div-text-size input[type="text"] {
  width: 50px;
  border-radius: 15%;
  padding: 0;
  margin-left: 10px;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

.div-text-size label {
  padding-right: 10px;
}

.div-text-size input {
  margin-bottom: 20px;
}


/*******************************************
Sélecteur de couleurs
*******************************************/
.vacp-color-picker {
  --vacp-color-space-width: 400px;
}


/*******************************************
Autres
*******************************************/
b {
  font-size: 18px;
}

b p {
  margin-bottom: 5px;
}

h2 {
  margin-bottom: 10px;
}
</style>