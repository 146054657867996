<template>
  <div id="sidebar" class="sidebar">
    <div class="icone-bloc">
      <div class="element">
        <!--Titre-->
        <div id="block-title" class="div-block" @click="this.newElement('block-title')">
          <img id="img-title" alt="Logo titre" class="img-icons" draggable="false"
               src="@/assets/icons-bloc/title.png"
               title='Ajouter un titre'>
        </div>
        <!--Paragraphe-->
        <div id="block-paragraph" class="div-block" @click="this.newElement('block-paragraph')">
          <img id="img-paragraph" alt="Logo paragraphe" class="img-icons"
               draggable="false"
               src="@/assets/icons-bloc/paragraph.png"
               title="Ajouter un paragraphe">
        </div>
        <!--Separateur-->
        <div id="block-separator" class="div-block" @click="this.newElement('block-separator')">
          <img id="img-separator" alt="Logo separateur" class="img-icons"
               draggable="false"
               src="@/assets/icons-bloc/separator.png"
               title="Ajouter un séparateur">
        </div>
        <!--Image-->
        <div id="block-image" class="div-block" @click="this.newElement('block-image')">
          <img id="img-image" alt="Logo image" class="img-icons" draggable="false"
               src="@/assets/icons-bloc/image.png"
               title="Ajouter une image">
        </div>
      </div>
    </div>
    <div id="div-icons-sr">
      <hr id="hr-down-section">
      <img id="img-reset" alt="Logo réinitialiser" class="img-icons-sr" draggable="false"
           src="@/assets/icons-bloc/reset.png" title="Tous effacer" @click="reset()">
      <img id="img-save" alt="logo sauvegarder" class="img-icons-sr" draggable="false"
           src="@/assets/icons-bloc/save.png" title="Sauvegarder" @click="sendCode()">
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    index: Number,
  },
  data() {
    return {
      i: 0,
    }
  },
  methods: {
    newElement(element) {
      this.$parent.newElement(element);
    },
    /**
     * Remettre en ordre la page
     * @param code
     */
    refreshPage(code) {

      this.i = parseInt(code.slice(code.length - 1, code.length));
      this.$emit("updateVar", {index: this.i, nbrAddedElements: this.i});

      code = code.slice(0, code.length - 1)

      // Efface les a dans les ids
      code = code.replaceAll(/id="a([0-9])*?"/ig, 'id="$1"');

      // Store
      this.$store.state.currentSelectedIndex = 500;

      // Met le bon nombre d'éléments dans la table
      this.$store.state.placedElementList = [];
      for (let i = 0; i < this.i; i++) {
        this.$store.state.placedElementList.push('a');
      }

      // Visu du code html
      const divCode = document.getElementById("div-formated-html");
      divCode.innerHTML = "<table></table>";

      // Visu du code css
      const tableFormatedCSS = document.getElementById("table-formated-css");
      tableFormatedCSS.innerHTML = "";

      // Cache la croix et les flèches
      let divVisu = document.querySelector(".div-visu")
      divVisu.innerHTML = code;
      document.querySelector(".cross-delete").style.display = "none";
      document.querySelector(".arrow-up").style.display = "none";
      document.querySelector(".arrow-down").style.display = "none";
    },

    /**
     * Efface la page
     */
    reset() {
      this.$swal.fire({
        title: 'Attention',
        text: "Vous allez perdre toutes vos modifications non sauvegardées. Êtes-vous sûr ?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonColor: '#FF6600',
        cancelButtonColor: '#e22222',
        confirmButtonText: 'Tous supprimer'
      }).then((result) => {
        if (result.isConfirmed) {

          // Efface le contenu de l'affichage CSS
          const tableFormatedCSS = document.getElementById("table-formated-css");
          tableFormatedCSS.innerHTML = "";

          // Supprime tous les elements
          for (let i = 0; i < this.$store.state.placedElementList.length; i++) {
            document.getElementById(i).remove();
          }

          // Reset la liste des elements
          this.$store.state.placedElementList = [];

          // Cache les icônes de manipulation
          document.querySelector(".cross-delete").style.display = "none";
          document.querySelector(".arrow-up").style.display = "none";
          document.querySelector(".arrow-down").style.display = "none";

          // Met à jour l'index
          this.$store.state.currentSelectedIndex = this.$store.state.baseIndexValue

          // Reset le nombre d'éléments sur la page
          this.$emit("updateVar", {index: 0, nbrAddedElements: 0});
          this.displayHTMLCode();
          this.displayCSSCode();
        }
      })
    },
    /**
     * Envoie le string du code à la database
     */
    sendCode() {
      if (this.$store.state.userData['user'] !== undefined) {
        const divCode = document.querySelector(".div-visu");
        let code = divCode.outerHTML;
        // Enlève les div de structure
        code = code.replace(/<.*?>/, '');
        code = code.slice(0, -6);
        // Enlève la selection
        code = code.replace("background-color: rgba(255, 102, 0, 0.25);", "");

        // Remplace les id en ajoutant un 'a' à chaque id numérique
        code = code.replaceAll(/id="([0-9])*?"/ig, 'id="a$1"');
        code += this.index;

        let website = code;
        let id = this.$store.state.userData['user'].id;
        let token = this.$store.state.userData.access_token;

        if (website === '') {
          // Informe l'utilisateur de l'échec
          this.$swal.fire({
            title: 'Erreur',
            text: "Vous ne pouvez pas sauvegarder un site vide.",
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#FF6600'
          })
        } else {
          if (this.$store.state.placedElementList.length <= 0) {
            // Appel l'api pour écrire dans website
            website = 'empty';
            this.$store.dispatch('addWebsite', {website, id, token});
          } else {
            // Appel l'api pour écrire dans website
            this.$store.dispatch('addWebsite', {website, id, token});
          }
          // Informe l'utilisateur de la réussite
          this.$swal.fire({
            title: 'Sauvegarde',
            text: "Votre site a été sauvegardé avec succès !",
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#FF6600'
          })
        }
      } else {
        this.$parent.displayPopupEmail();
      }
    },
    displayCSSCode() {
      this.$parent.displayCSSCode();
    },
    displayHTMLCode() {
      this.$parent.displayHTMLCode();
    },
  }
}
</script>

<style lang="scss" scoped>
/*******************************************
Général
*******************************************/
#hr-down-section {
  width: 85%;
  height: 0;
  border: 1px solid black;
}

.div-block {
  margin-bottom: 80px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*******************************************
Sidebar
*******************************************/
.sidebar {
  height: 100%;
  width: 85px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #C4C4C4;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 20px 10px 10px 10px;
}

.sidebar img {
  height: 50px;
  width: 50px;
}

#block-title {
  margin-top: 20px;
}


/*******************************************
Blocs et boutons
*******************************************/
img:hover {
  transition: 0.5s;
  transform: scale(1.1);
}

#img-title:hover {
  content:url("@/assets/icons-bloc/title-hover.png");
}
#img-paragraph:hover {
  content:url("@/assets/icons-bloc/paragraph-hover.png");
}
#img-separator:hover {
  content:url("@/assets/icons-bloc/separator-hover.png");
}
#img-image:hover {
  content:url("@/assets/icons-bloc/image-hover.png");
}


.icone-bloc {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#div-icons-sr {
  position: absolute;
  bottom: 40px;
  width: 100%;
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-icons-sr {
  height: 50px;
  width: 50px;
  margin-top: 12%;
}

.img-icons {
  opacity: 1;
  transition: 0.5s;
}

.div-block .block-title h1 {
  margin: 0;
  padding: 3%;
}

.img-icons-sr {
  padding-bottom: 5px;
}

#img-reset {
  padding-top: 5px;
}

.sidebar .element img {
  height: 100%;
  width: auto;
}

#img-reset:hover, #img-save:hover, .div-block:hover {
  cursor: pointer;
}
</style>
